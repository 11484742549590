@charset "UTF-8";
@import '~swiper/swiper-bundle.css';
@font-face {
  font-family: "Quattrocento Sans";
  src: url("../../fonts/QuattrocentoSans-Bold.woff2") format("woff2"), url("../../fonts/QuattrocentoSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Quattrocento Sans";
  src: url("../../fonts/QuattrocentoSans.woff2") format("woff2"), url("../../fonts/QuattrocentoSans.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src: url("../../fonts/OpenSans-Bold.woff2") format("woff2"), url("../../fonts/OpenSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src: url("../../fonts/OpenSans-Regular.woff2") format("woff2"), url("../../fonts/OpenSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.grid-container {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
}
@media print, screen and (min-width: 40em) {
  .grid-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.grid-container.fluid {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
@media print, screen and (min-width: 40em) {
  .grid-container.fluid {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.grid-container.full {
  padding-right: 0;
  padding-left: 0;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.grid-x {
  display: flex;
  flex-flow: row wrap;
}

.cell {
  flex: 0 0 auto;
  min-height: 0;
  min-width: 0;
  width: 100%;
}
.cell.auto {
  flex: 1 1 0;
}
.cell.shrink {
  flex: 0 0 auto;
}

.grid-x > .auto {
  width: auto;
}
.grid-x > .shrink {
  width: auto;
}

.grid-x > .small-shrink, .grid-x > .small-full, .grid-x > .small-1, .grid-x > .small-2, .grid-x > .small-3, .grid-x > .small-4, .grid-x > .small-5, .grid-x > .small-6, .grid-x > .small-7, .grid-x > .small-8, .grid-x > .small-9, .grid-x > .small-10, .grid-x > .small-11, .grid-x > .small-12 {
  flex-basis: auto;
}

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-shrink, .grid-x > .medium-full, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12 {
    flex-basis: auto;
  }
}
@media print, screen and (min-width: 64em) {
  .grid-x > .large-shrink, .grid-x > .large-full, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12 {
    flex-basis: auto;
  }
}
.grid-x > .small-12, .grid-x > .small-11, .grid-x > .small-10, .grid-x > .small-9, .grid-x > .small-8, .grid-x > .small-7, .grid-x > .small-6, .grid-x > .small-5, .grid-x > .small-4, .grid-x > .small-3, .grid-x > .small-2, .grid-x > .small-1 {
  flex: 0 0 auto;
}

.grid-x > .small-1 {
  width: 8.3333333333%;
}

.grid-x > .small-2 {
  width: 16.6666666667%;
}

.grid-x > .small-3 {
  width: 25%;
}

.grid-x > .small-4 {
  width: 33.3333333333%;
}

.grid-x > .small-5 {
  width: 41.6666666667%;
}

.grid-x > .small-6 {
  width: 50%;
}

.grid-x > .small-7 {
  width: 58.3333333333%;
}

.grid-x > .small-8 {
  width: 66.6666666667%;
}

.grid-x > .small-9 {
  width: 75%;
}

.grid-x > .small-10 {
  width: 83.3333333333%;
}

.grid-x > .small-11 {
  width: 91.6666666667%;
}

.grid-x > .small-12 {
  width: 100%;
}

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-auto {
    flex: 1 1 0;
    width: auto;
  }

  .grid-x > .medium-12, .grid-x > .medium-11, .grid-x > .medium-10, .grid-x > .medium-9, .grid-x > .medium-8, .grid-x > .medium-7, .grid-x > .medium-6, .grid-x > .medium-5, .grid-x > .medium-4, .grid-x > .medium-3, .grid-x > .medium-2, .grid-x > .medium-1, .grid-x > .medium-shrink {
    flex: 0 0 auto;
  }

  .grid-x > .medium-shrink {
    width: auto;
  }

  .grid-x > .medium-1 {
    width: 8.3333333333%;
  }

  .grid-x > .medium-2 {
    width: 16.6666666667%;
  }

  .grid-x > .medium-3 {
    width: 25%;
  }

  .grid-x > .medium-4 {
    width: 33.3333333333%;
  }

  .grid-x > .medium-5 {
    width: 41.6666666667%;
  }

  .grid-x > .medium-6 {
    width: 50%;
  }

  .grid-x > .medium-7 {
    width: 58.3333333333%;
  }

  .grid-x > .medium-8 {
    width: 66.6666666667%;
  }

  .grid-x > .medium-9 {
    width: 75%;
  }

  .grid-x > .medium-10 {
    width: 83.3333333333%;
  }

  .grid-x > .medium-11 {
    width: 91.6666666667%;
  }

  .grid-x > .medium-12 {
    width: 100%;
  }
}
@media print, screen and (min-width: 64em) {
  .grid-x > .large-auto {
    flex: 1 1 0;
    width: auto;
  }

  .grid-x > .large-12, .grid-x > .large-11, .grid-x > .large-10, .grid-x > .large-9, .grid-x > .large-8, .grid-x > .large-7, .grid-x > .large-6, .grid-x > .large-5, .grid-x > .large-4, .grid-x > .large-3, .grid-x > .large-2, .grid-x > .large-1, .grid-x > .large-shrink {
    flex: 0 0 auto;
  }

  .grid-x > .large-shrink {
    width: auto;
  }

  .grid-x > .large-1 {
    width: 8.3333333333%;
  }

  .grid-x > .large-2 {
    width: 16.6666666667%;
  }

  .grid-x > .large-3 {
    width: 25%;
  }

  .grid-x > .large-4 {
    width: 33.3333333333%;
  }

  .grid-x > .large-5 {
    width: 41.6666666667%;
  }

  .grid-x > .large-6 {
    width: 50%;
  }

  .grid-x > .large-7 {
    width: 58.3333333333%;
  }

  .grid-x > .large-8 {
    width: 66.6666666667%;
  }

  .grid-x > .large-9 {
    width: 75%;
  }

  .grid-x > .large-10 {
    width: 83.3333333333%;
  }

  .grid-x > .large-11 {
    width: 91.6666666667%;
  }

  .grid-x > .large-12 {
    width: 100%;
  }
}
.grid-margin-x:not(.grid-x) > .cell {
  width: auto;
}

.grid-margin-y:not(.grid-y) > .cell {
  height: auto;
}

.grid-margin-x {
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}
@media print, screen and (min-width: 40em) {
  .grid-margin-x {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem;
  }
}
.grid-margin-x > .cell {
  width: calc(100% - 1.25rem);
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}
@media print, screen and (min-width: 40em) {
  .grid-margin-x > .cell {
    width: calc(100% - 1.875rem);
    margin-left: 0.9375rem;
    margin-right: 0.9375rem;
  }
}
.grid-margin-x > .auto {
  width: auto;
}
.grid-margin-x > .shrink {
  width: auto;
}
.grid-margin-x > .small-1 {
  width: calc(8.3333333333% - 1.25rem);
}
.grid-margin-x > .small-2 {
  width: calc(16.6666666667% - 1.25rem);
}
.grid-margin-x > .small-3 {
  width: calc(25% - 1.25rem);
}
.grid-margin-x > .small-4 {
  width: calc(33.3333333333% - 1.25rem);
}
.grid-margin-x > .small-5 {
  width: calc(41.6666666667% - 1.25rem);
}
.grid-margin-x > .small-6 {
  width: calc(50% - 1.25rem);
}
.grid-margin-x > .small-7 {
  width: calc(58.3333333333% - 1.25rem);
}
.grid-margin-x > .small-8 {
  width: calc(66.6666666667% - 1.25rem);
}
.grid-margin-x > .small-9 {
  width: calc(75% - 1.25rem);
}
.grid-margin-x > .small-10 {
  width: calc(83.3333333333% - 1.25rem);
}
.grid-margin-x > .small-11 {
  width: calc(91.6666666667% - 1.25rem);
}
.grid-margin-x > .small-12 {
  width: calc(100% - 1.25rem);
}
@media print, screen and (min-width: 40em) {
  .grid-margin-x > .auto {
    width: auto;
  }
  .grid-margin-x > .shrink {
    width: auto;
  }
  .grid-margin-x > .small-1 {
    width: calc(8.3333333333% - 1.875rem);
  }
  .grid-margin-x > .small-2 {
    width: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-x > .small-3 {
    width: calc(25% - 1.875rem);
  }
  .grid-margin-x > .small-4 {
    width: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-x > .small-5 {
    width: calc(41.6666666667% - 1.875rem);
  }
  .grid-margin-x > .small-6 {
    width: calc(50% - 1.875rem);
  }
  .grid-margin-x > .small-7 {
    width: calc(58.3333333333% - 1.875rem);
  }
  .grid-margin-x > .small-8 {
    width: calc(66.6666666667% - 1.875rem);
  }
  .grid-margin-x > .small-9 {
    width: calc(75% - 1.875rem);
  }
  .grid-margin-x > .small-10 {
    width: calc(83.3333333333% - 1.875rem);
  }
  .grid-margin-x > .small-11 {
    width: calc(91.6666666667% - 1.875rem);
  }
  .grid-margin-x > .small-12 {
    width: calc(100% - 1.875rem);
  }
  .grid-margin-x > .medium-auto {
    width: auto;
  }
  .grid-margin-x > .medium-shrink {
    width: auto;
  }
  .grid-margin-x > .medium-1 {
    width: calc(8.3333333333% - 1.875rem);
  }
  .grid-margin-x > .medium-2 {
    width: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-x > .medium-3 {
    width: calc(25% - 1.875rem);
  }
  .grid-margin-x > .medium-4 {
    width: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-x > .medium-5 {
    width: calc(41.6666666667% - 1.875rem);
  }
  .grid-margin-x > .medium-6 {
    width: calc(50% - 1.875rem);
  }
  .grid-margin-x > .medium-7 {
    width: calc(58.3333333333% - 1.875rem);
  }
  .grid-margin-x > .medium-8 {
    width: calc(66.6666666667% - 1.875rem);
  }
  .grid-margin-x > .medium-9 {
    width: calc(75% - 1.875rem);
  }
  .grid-margin-x > .medium-10 {
    width: calc(83.3333333333% - 1.875rem);
  }
  .grid-margin-x > .medium-11 {
    width: calc(91.6666666667% - 1.875rem);
  }
  .grid-margin-x > .medium-12 {
    width: calc(100% - 1.875rem);
  }
}
@media print, screen and (min-width: 64em) {
  .grid-margin-x > .large-auto {
    width: auto;
  }
  .grid-margin-x > .large-shrink {
    width: auto;
  }
  .grid-margin-x > .large-1 {
    width: calc(8.3333333333% - 1.875rem);
  }
  .grid-margin-x > .large-2 {
    width: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-x > .large-3 {
    width: calc(25% - 1.875rem);
  }
  .grid-margin-x > .large-4 {
    width: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-x > .large-5 {
    width: calc(41.6666666667% - 1.875rem);
  }
  .grid-margin-x > .large-6 {
    width: calc(50% - 1.875rem);
  }
  .grid-margin-x > .large-7 {
    width: calc(58.3333333333% - 1.875rem);
  }
  .grid-margin-x > .large-8 {
    width: calc(66.6666666667% - 1.875rem);
  }
  .grid-margin-x > .large-9 {
    width: calc(75% - 1.875rem);
  }
  .grid-margin-x > .large-10 {
    width: calc(83.3333333333% - 1.875rem);
  }
  .grid-margin-x > .large-11 {
    width: calc(91.6666666667% - 1.875rem);
  }
  .grid-margin-x > .large-12 {
    width: calc(100% - 1.875rem);
  }
}

.grid-padding-x .grid-padding-x {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
@media print, screen and (min-width: 40em) {
  .grid-padding-x .grid-padding-x {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
.grid-container:not(.full) > .grid-padding-x {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
@media print, screen and (min-width: 40em) {
  .grid-container:not(.full) > .grid-padding-x {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
.grid-padding-x > .cell {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}
@media print, screen and (min-width: 40em) {
  .grid-padding-x > .cell {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.small-up-1 > .cell {
  width: 100%;
}

.small-up-2 > .cell {
  width: 50%;
}

.small-up-3 > .cell {
  width: 33.3333333333%;
}

.small-up-4 > .cell {
  width: 25%;
}

.small-up-5 > .cell {
  width: 20%;
}

.small-up-6 > .cell {
  width: 16.6666666667%;
}

.small-up-7 > .cell {
  width: 14.2857142857%;
}

.small-up-8 > .cell {
  width: 12.5%;
}

@media print, screen and (min-width: 40em) {
  .medium-up-1 > .cell {
    width: 100%;
  }

  .medium-up-2 > .cell {
    width: 50%;
  }

  .medium-up-3 > .cell {
    width: 33.3333333333%;
  }

  .medium-up-4 > .cell {
    width: 25%;
  }

  .medium-up-5 > .cell {
    width: 20%;
  }

  .medium-up-6 > .cell {
    width: 16.6666666667%;
  }

  .medium-up-7 > .cell {
    width: 14.2857142857%;
  }

  .medium-up-8 > .cell {
    width: 12.5%;
  }
}
@media print, screen and (min-width: 64em) {
  .large-up-1 > .cell {
    width: 100%;
  }

  .large-up-2 > .cell {
    width: 50%;
  }

  .large-up-3 > .cell {
    width: 33.3333333333%;
  }

  .large-up-4 > .cell {
    width: 25%;
  }

  .large-up-5 > .cell {
    width: 20%;
  }

  .large-up-6 > .cell {
    width: 16.6666666667%;
  }

  .large-up-7 > .cell {
    width: 14.2857142857%;
  }

  .large-up-8 > .cell {
    width: 12.5%;
  }
}
.grid-margin-x.small-up-1 > .cell {
  width: calc(100% - 1.25rem);
}

.grid-margin-x.small-up-2 > .cell {
  width: calc(50% - 1.25rem);
}

.grid-margin-x.small-up-3 > .cell {
  width: calc(33.3333333333% - 1.25rem);
}

.grid-margin-x.small-up-4 > .cell {
  width: calc(25% - 1.25rem);
}

.grid-margin-x.small-up-5 > .cell {
  width: calc(20% - 1.25rem);
}

.grid-margin-x.small-up-6 > .cell {
  width: calc(16.6666666667% - 1.25rem);
}

.grid-margin-x.small-up-7 > .cell {
  width: calc(14.2857142857% - 1.25rem);
}

.grid-margin-x.small-up-8 > .cell {
  width: calc(12.5% - 1.25rem);
}

@media print, screen and (min-width: 40em) {
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 1.875rem);
  }

  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 1.875rem);
  }

  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.3333333333% - 1.875rem);
  }

  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 1.875rem);
  }

  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 1.875rem);
  }

  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.6666666667% - 1.875rem);
  }

  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.2857142857% - 1.875rem);
  }

  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 1.875rem);
  }

  .grid-margin-x.medium-up-1 > .cell {
    width: calc(100% - 1.875rem);
  }

  .grid-margin-x.medium-up-2 > .cell {
    width: calc(50% - 1.875rem);
  }

  .grid-margin-x.medium-up-3 > .cell {
    width: calc(33.3333333333% - 1.875rem);
  }

  .grid-margin-x.medium-up-4 > .cell {
    width: calc(25% - 1.875rem);
  }

  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 1.875rem);
  }

  .grid-margin-x.medium-up-6 > .cell {
    width: calc(16.6666666667% - 1.875rem);
  }

  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.2857142857% - 1.875rem);
  }

  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 1.875rem);
  }
}
@media print, screen and (min-width: 64em) {
  .grid-margin-x.large-up-1 > .cell {
    width: calc(100% - 1.875rem);
  }

  .grid-margin-x.large-up-2 > .cell {
    width: calc(50% - 1.875rem);
  }

  .grid-margin-x.large-up-3 > .cell {
    width: calc(33.3333333333% - 1.875rem);
  }

  .grid-margin-x.large-up-4 > .cell {
    width: calc(25% - 1.875rem);
  }

  .grid-margin-x.large-up-5 > .cell {
    width: calc(20% - 1.875rem);
  }

  .grid-margin-x.large-up-6 > .cell {
    width: calc(16.6666666667% - 1.875rem);
  }

  .grid-margin-x.large-up-7 > .cell {
    width: calc(14.2857142857% - 1.875rem);
  }

  .grid-margin-x.large-up-8 > .cell {
    width: calc(12.5% - 1.875rem);
  }
}
.small-margin-collapse {
  margin-right: 0;
  margin-left: 0;
}
.small-margin-collapse > .cell {
  margin-right: 0;
  margin-left: 0;
}
.small-margin-collapse > .small-1 {
  width: 8.3333333333%;
}
.small-margin-collapse > .small-2 {
  width: 16.6666666667%;
}
.small-margin-collapse > .small-3 {
  width: 25%;
}
.small-margin-collapse > .small-4 {
  width: 33.3333333333%;
}
.small-margin-collapse > .small-5 {
  width: 41.6666666667%;
}
.small-margin-collapse > .small-6 {
  width: 50%;
}
.small-margin-collapse > .small-7 {
  width: 58.3333333333%;
}
.small-margin-collapse > .small-8 {
  width: 66.6666666667%;
}
.small-margin-collapse > .small-9 {
  width: 75%;
}
.small-margin-collapse > .small-10 {
  width: 83.3333333333%;
}
.small-margin-collapse > .small-11 {
  width: 91.6666666667%;
}
.small-margin-collapse > .small-12 {
  width: 100%;
}
@media print, screen and (min-width: 40em) {
  .small-margin-collapse > .medium-1 {
    width: 8.3333333333%;
  }
  .small-margin-collapse > .medium-2 {
    width: 16.6666666667%;
  }
  .small-margin-collapse > .medium-3 {
    width: 25%;
  }
  .small-margin-collapse > .medium-4 {
    width: 33.3333333333%;
  }
  .small-margin-collapse > .medium-5 {
    width: 41.6666666667%;
  }
  .small-margin-collapse > .medium-6 {
    width: 50%;
  }
  .small-margin-collapse > .medium-7 {
    width: 58.3333333333%;
  }
  .small-margin-collapse > .medium-8 {
    width: 66.6666666667%;
  }
  .small-margin-collapse > .medium-9 {
    width: 75%;
  }
  .small-margin-collapse > .medium-10 {
    width: 83.3333333333%;
  }
  .small-margin-collapse > .medium-11 {
    width: 91.6666666667%;
  }
  .small-margin-collapse > .medium-12 {
    width: 100%;
  }
}
@media print, screen and (min-width: 64em) {
  .small-margin-collapse > .large-1 {
    width: 8.3333333333%;
  }
  .small-margin-collapse > .large-2 {
    width: 16.6666666667%;
  }
  .small-margin-collapse > .large-3 {
    width: 25%;
  }
  .small-margin-collapse > .large-4 {
    width: 33.3333333333%;
  }
  .small-margin-collapse > .large-5 {
    width: 41.6666666667%;
  }
  .small-margin-collapse > .large-6 {
    width: 50%;
  }
  .small-margin-collapse > .large-7 {
    width: 58.3333333333%;
  }
  .small-margin-collapse > .large-8 {
    width: 66.6666666667%;
  }
  .small-margin-collapse > .large-9 {
    width: 75%;
  }
  .small-margin-collapse > .large-10 {
    width: 83.3333333333%;
  }
  .small-margin-collapse > .large-11 {
    width: 91.6666666667%;
  }
  .small-margin-collapse > .large-12 {
    width: 100%;
  }
}

.small-padding-collapse {
  margin-right: 0;
  margin-left: 0;
}
.small-padding-collapse > .cell {
  padding-right: 0;
  padding-left: 0;
}

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .medium-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0;
  }
}
@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .small-1 {
    width: 8.3333333333%;
  }
  .medium-margin-collapse > .small-2 {
    width: 16.6666666667%;
  }
  .medium-margin-collapse > .small-3 {
    width: 25%;
  }
  .medium-margin-collapse > .small-4 {
    width: 33.3333333333%;
  }
  .medium-margin-collapse > .small-5 {
    width: 41.6666666667%;
  }
  .medium-margin-collapse > .small-6 {
    width: 50%;
  }
  .medium-margin-collapse > .small-7 {
    width: 58.3333333333%;
  }
  .medium-margin-collapse > .small-8 {
    width: 66.6666666667%;
  }
  .medium-margin-collapse > .small-9 {
    width: 75%;
  }
  .medium-margin-collapse > .small-10 {
    width: 83.3333333333%;
  }
  .medium-margin-collapse > .small-11 {
    width: 91.6666666667%;
  }
  .medium-margin-collapse > .small-12 {
    width: 100%;
  }
}
@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .medium-1 {
    width: 8.3333333333%;
  }
  .medium-margin-collapse > .medium-2 {
    width: 16.6666666667%;
  }
  .medium-margin-collapse > .medium-3 {
    width: 25%;
  }
  .medium-margin-collapse > .medium-4 {
    width: 33.3333333333%;
  }
  .medium-margin-collapse > .medium-5 {
    width: 41.6666666667%;
  }
  .medium-margin-collapse > .medium-6 {
    width: 50%;
  }
  .medium-margin-collapse > .medium-7 {
    width: 58.3333333333%;
  }
  .medium-margin-collapse > .medium-8 {
    width: 66.6666666667%;
  }
  .medium-margin-collapse > .medium-9 {
    width: 75%;
  }
  .medium-margin-collapse > .medium-10 {
    width: 83.3333333333%;
  }
  .medium-margin-collapse > .medium-11 {
    width: 91.6666666667%;
  }
  .medium-margin-collapse > .medium-12 {
    width: 100%;
  }
}
@media print, screen and (min-width: 64em) {
  .medium-margin-collapse > .large-1 {
    width: 8.3333333333%;
  }
  .medium-margin-collapse > .large-2 {
    width: 16.6666666667%;
  }
  .medium-margin-collapse > .large-3 {
    width: 25%;
  }
  .medium-margin-collapse > .large-4 {
    width: 33.3333333333%;
  }
  .medium-margin-collapse > .large-5 {
    width: 41.6666666667%;
  }
  .medium-margin-collapse > .large-6 {
    width: 50%;
  }
  .medium-margin-collapse > .large-7 {
    width: 58.3333333333%;
  }
  .medium-margin-collapse > .large-8 {
    width: 66.6666666667%;
  }
  .medium-margin-collapse > .large-9 {
    width: 75%;
  }
  .medium-margin-collapse > .large-10 {
    width: 83.3333333333%;
  }
  .medium-margin-collapse > .large-11 {
    width: 91.6666666667%;
  }
  .medium-margin-collapse > .large-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-padding-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .medium-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0;
  }
}

@media print, screen and (min-width: 64em) {
  .large-margin-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .large-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0;
  }
}
@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .small-1 {
    width: 8.3333333333%;
  }
  .large-margin-collapse > .small-2 {
    width: 16.6666666667%;
  }
  .large-margin-collapse > .small-3 {
    width: 25%;
  }
  .large-margin-collapse > .small-4 {
    width: 33.3333333333%;
  }
  .large-margin-collapse > .small-5 {
    width: 41.6666666667%;
  }
  .large-margin-collapse > .small-6 {
    width: 50%;
  }
  .large-margin-collapse > .small-7 {
    width: 58.3333333333%;
  }
  .large-margin-collapse > .small-8 {
    width: 66.6666666667%;
  }
  .large-margin-collapse > .small-9 {
    width: 75%;
  }
  .large-margin-collapse > .small-10 {
    width: 83.3333333333%;
  }
  .large-margin-collapse > .small-11 {
    width: 91.6666666667%;
  }
  .large-margin-collapse > .small-12 {
    width: 100%;
  }
}
@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .medium-1 {
    width: 8.3333333333%;
  }
  .large-margin-collapse > .medium-2 {
    width: 16.6666666667%;
  }
  .large-margin-collapse > .medium-3 {
    width: 25%;
  }
  .large-margin-collapse > .medium-4 {
    width: 33.3333333333%;
  }
  .large-margin-collapse > .medium-5 {
    width: 41.6666666667%;
  }
  .large-margin-collapse > .medium-6 {
    width: 50%;
  }
  .large-margin-collapse > .medium-7 {
    width: 58.3333333333%;
  }
  .large-margin-collapse > .medium-8 {
    width: 66.6666666667%;
  }
  .large-margin-collapse > .medium-9 {
    width: 75%;
  }
  .large-margin-collapse > .medium-10 {
    width: 83.3333333333%;
  }
  .large-margin-collapse > .medium-11 {
    width: 91.6666666667%;
  }
  .large-margin-collapse > .medium-12 {
    width: 100%;
  }
}
@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .large-1 {
    width: 8.3333333333%;
  }
  .large-margin-collapse > .large-2 {
    width: 16.6666666667%;
  }
  .large-margin-collapse > .large-3 {
    width: 25%;
  }
  .large-margin-collapse > .large-4 {
    width: 33.3333333333%;
  }
  .large-margin-collapse > .large-5 {
    width: 41.6666666667%;
  }
  .large-margin-collapse > .large-6 {
    width: 50%;
  }
  .large-margin-collapse > .large-7 {
    width: 58.3333333333%;
  }
  .large-margin-collapse > .large-8 {
    width: 66.6666666667%;
  }
  .large-margin-collapse > .large-9 {
    width: 75%;
  }
  .large-margin-collapse > .large-10 {
    width: 83.3333333333%;
  }
  .large-margin-collapse > .large-11 {
    width: 91.6666666667%;
  }
  .large-margin-collapse > .large-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .large-padding-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .large-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0;
  }
}

.small-offset-0 {
  margin-left: 0%;
}

.grid-margin-x > .small-offset-0 {
  margin-left: calc(0% + 1.25rem / 2);
}

.small-offset-1 {
  margin-left: 8.3333333333%;
}

.grid-margin-x > .small-offset-1 {
  margin-left: calc(8.3333333333% + 1.25rem / 2);
}

.small-offset-2 {
  margin-left: 16.6666666667%;
}

.grid-margin-x > .small-offset-2 {
  margin-left: calc(16.6666666667% + 1.25rem / 2);
}

.small-offset-3 {
  margin-left: 25%;
}

.grid-margin-x > .small-offset-3 {
  margin-left: calc(25% + 1.25rem / 2);
}

.small-offset-4 {
  margin-left: 33.3333333333%;
}

.grid-margin-x > .small-offset-4 {
  margin-left: calc(33.3333333333% + 1.25rem / 2);
}

.small-offset-5 {
  margin-left: 41.6666666667%;
}

.grid-margin-x > .small-offset-5 {
  margin-left: calc(41.6666666667% + 1.25rem / 2);
}

.small-offset-6 {
  margin-left: 50%;
}

.grid-margin-x > .small-offset-6 {
  margin-left: calc(50% + 1.25rem / 2);
}

.small-offset-7 {
  margin-left: 58.3333333333%;
}

.grid-margin-x > .small-offset-7 {
  margin-left: calc(58.3333333333% + 1.25rem / 2);
}

.small-offset-8 {
  margin-left: 66.6666666667%;
}

.grid-margin-x > .small-offset-8 {
  margin-left: calc(66.6666666667% + 1.25rem / 2);
}

.small-offset-9 {
  margin-left: 75%;
}

.grid-margin-x > .small-offset-9 {
  margin-left: calc(75% + 1.25rem / 2);
}

.small-offset-10 {
  margin-left: 83.3333333333%;
}

.grid-margin-x > .small-offset-10 {
  margin-left: calc(83.3333333333% + 1.25rem / 2);
}

.small-offset-11 {
  margin-left: 91.6666666667%;
}

.grid-margin-x > .small-offset-11 {
  margin-left: calc(91.6666666667% + 1.25rem / 2);
}

@media print, screen and (min-width: 40em) {
  .medium-offset-0 {
    margin-left: 0%;
  }

  .grid-margin-x > .medium-offset-0 {
    margin-left: calc(0% + 1.875rem / 2);
  }

  .medium-offset-1 {
    margin-left: 8.3333333333%;
  }

  .grid-margin-x > .medium-offset-1 {
    margin-left: calc(8.3333333333% + 1.875rem / 2);
  }

  .medium-offset-2 {
    margin-left: 16.6666666667%;
  }

  .grid-margin-x > .medium-offset-2 {
    margin-left: calc(16.6666666667% + 1.875rem / 2);
  }

  .medium-offset-3 {
    margin-left: 25%;
  }

  .grid-margin-x > .medium-offset-3 {
    margin-left: calc(25% + 1.875rem / 2);
  }

  .medium-offset-4 {
    margin-left: 33.3333333333%;
  }

  .grid-margin-x > .medium-offset-4 {
    margin-left: calc(33.3333333333% + 1.875rem / 2);
  }

  .medium-offset-5 {
    margin-left: 41.6666666667%;
  }

  .grid-margin-x > .medium-offset-5 {
    margin-left: calc(41.6666666667% + 1.875rem / 2);
  }

  .medium-offset-6 {
    margin-left: 50%;
  }

  .grid-margin-x > .medium-offset-6 {
    margin-left: calc(50% + 1.875rem / 2);
  }

  .medium-offset-7 {
    margin-left: 58.3333333333%;
  }

  .grid-margin-x > .medium-offset-7 {
    margin-left: calc(58.3333333333% + 1.875rem / 2);
  }

  .medium-offset-8 {
    margin-left: 66.6666666667%;
  }

  .grid-margin-x > .medium-offset-8 {
    margin-left: calc(66.6666666667% + 1.875rem / 2);
  }

  .medium-offset-9 {
    margin-left: 75%;
  }

  .grid-margin-x > .medium-offset-9 {
    margin-left: calc(75% + 1.875rem / 2);
  }

  .medium-offset-10 {
    margin-left: 83.3333333333%;
  }

  .grid-margin-x > .medium-offset-10 {
    margin-left: calc(83.3333333333% + 1.875rem / 2);
  }

  .medium-offset-11 {
    margin-left: 91.6666666667%;
  }

  .grid-margin-x > .medium-offset-11 {
    margin-left: calc(91.6666666667% + 1.875rem / 2);
  }
}
@media print, screen and (min-width: 64em) {
  .large-offset-0 {
    margin-left: 0%;
  }

  .grid-margin-x > .large-offset-0 {
    margin-left: calc(0% + 1.875rem / 2);
  }

  .large-offset-1 {
    margin-left: 8.3333333333%;
  }

  .grid-margin-x > .large-offset-1 {
    margin-left: calc(8.3333333333% + 1.875rem / 2);
  }

  .large-offset-2 {
    margin-left: 16.6666666667%;
  }

  .grid-margin-x > .large-offset-2 {
    margin-left: calc(16.6666666667% + 1.875rem / 2);
  }

  .large-offset-3 {
    margin-left: 25%;
  }

  .grid-margin-x > .large-offset-3 {
    margin-left: calc(25% + 1.875rem / 2);
  }

  .large-offset-4 {
    margin-left: 33.3333333333%;
  }

  .grid-margin-x > .large-offset-4 {
    margin-left: calc(33.3333333333% + 1.875rem / 2);
  }

  .large-offset-5 {
    margin-left: 41.6666666667%;
  }

  .grid-margin-x > .large-offset-5 {
    margin-left: calc(41.6666666667% + 1.875rem / 2);
  }

  .large-offset-6 {
    margin-left: 50%;
  }

  .grid-margin-x > .large-offset-6 {
    margin-left: calc(50% + 1.875rem / 2);
  }

  .large-offset-7 {
    margin-left: 58.3333333333%;
  }

  .grid-margin-x > .large-offset-7 {
    margin-left: calc(58.3333333333% + 1.875rem / 2);
  }

  .large-offset-8 {
    margin-left: 66.6666666667%;
  }

  .grid-margin-x > .large-offset-8 {
    margin-left: calc(66.6666666667% + 1.875rem / 2);
  }

  .large-offset-9 {
    margin-left: 75%;
  }

  .grid-margin-x > .large-offset-9 {
    margin-left: calc(75% + 1.875rem / 2);
  }

  .large-offset-10 {
    margin-left: 83.3333333333%;
  }

  .grid-margin-x > .large-offset-10 {
    margin-left: calc(83.3333333333% + 1.875rem / 2);
  }

  .large-offset-11 {
    margin-left: 91.6666666667%;
  }

  .grid-margin-x > .large-offset-11 {
    margin-left: calc(91.6666666667% + 1.875rem / 2);
  }
}
.grid-y {
  display: flex;
  flex-flow: column nowrap;
}
.grid-y > .cell {
  height: auto;
  max-height: none;
}
.grid-y > .auto {
  height: auto;
}
.grid-y > .shrink {
  height: auto;
}
.grid-y > .small-shrink, .grid-y > .small-full, .grid-y > .small-1, .grid-y > .small-2, .grid-y > .small-3, .grid-y > .small-4, .grid-y > .small-5, .grid-y > .small-6, .grid-y > .small-7, .grid-y > .small-8, .grid-y > .small-9, .grid-y > .small-10, .grid-y > .small-11, .grid-y > .small-12 {
  flex-basis: auto;
}
@media print, screen and (min-width: 40em) {
  .grid-y > .medium-shrink, .grid-y > .medium-full, .grid-y > .medium-1, .grid-y > .medium-2, .grid-y > .medium-3, .grid-y > .medium-4, .grid-y > .medium-5, .grid-y > .medium-6, .grid-y > .medium-7, .grid-y > .medium-8, .grid-y > .medium-9, .grid-y > .medium-10, .grid-y > .medium-11, .grid-y > .medium-12 {
    flex-basis: auto;
  }
}
@media print, screen and (min-width: 64em) {
  .grid-y > .large-shrink, .grid-y > .large-full, .grid-y > .large-1, .grid-y > .large-2, .grid-y > .large-3, .grid-y > .large-4, .grid-y > .large-5, .grid-y > .large-6, .grid-y > .large-7, .grid-y > .large-8, .grid-y > .large-9, .grid-y > .large-10, .grid-y > .large-11, .grid-y > .large-12 {
    flex-basis: auto;
  }
}
.grid-y > .small-12, .grid-y > .small-11, .grid-y > .small-10, .grid-y > .small-9, .grid-y > .small-8, .grid-y > .small-7, .grid-y > .small-6, .grid-y > .small-5, .grid-y > .small-4, .grid-y > .small-3, .grid-y > .small-2, .grid-y > .small-1 {
  flex: 0 0 auto;
}
.grid-y > .small-1 {
  height: 8.3333333333%;
}
.grid-y > .small-2 {
  height: 16.6666666667%;
}
.grid-y > .small-3 {
  height: 25%;
}
.grid-y > .small-4 {
  height: 33.3333333333%;
}
.grid-y > .small-5 {
  height: 41.6666666667%;
}
.grid-y > .small-6 {
  height: 50%;
}
.grid-y > .small-7 {
  height: 58.3333333333%;
}
.grid-y > .small-8 {
  height: 66.6666666667%;
}
.grid-y > .small-9 {
  height: 75%;
}
.grid-y > .small-10 {
  height: 83.3333333333%;
}
.grid-y > .small-11 {
  height: 91.6666666667%;
}
.grid-y > .small-12 {
  height: 100%;
}
@media print, screen and (min-width: 40em) {
  .grid-y > .medium-auto {
    flex: 1 1 0;
    height: auto;
  }
  .grid-y > .medium-12, .grid-y > .medium-11, .grid-y > .medium-10, .grid-y > .medium-9, .grid-y > .medium-8, .grid-y > .medium-7, .grid-y > .medium-6, .grid-y > .medium-5, .grid-y > .medium-4, .grid-y > .medium-3, .grid-y > .medium-2, .grid-y > .medium-1, .grid-y > .medium-shrink {
    flex: 0 0 auto;
  }
  .grid-y > .medium-shrink {
    height: auto;
  }
  .grid-y > .medium-1 {
    height: 8.3333333333%;
  }
  .grid-y > .medium-2 {
    height: 16.6666666667%;
  }
  .grid-y > .medium-3 {
    height: 25%;
  }
  .grid-y > .medium-4 {
    height: 33.3333333333%;
  }
  .grid-y > .medium-5 {
    height: 41.6666666667%;
  }
  .grid-y > .medium-6 {
    height: 50%;
  }
  .grid-y > .medium-7 {
    height: 58.3333333333%;
  }
  .grid-y > .medium-8 {
    height: 66.6666666667%;
  }
  .grid-y > .medium-9 {
    height: 75%;
  }
  .grid-y > .medium-10 {
    height: 83.3333333333%;
  }
  .grid-y > .medium-11 {
    height: 91.6666666667%;
  }
  .grid-y > .medium-12 {
    height: 100%;
  }
}
@media print, screen and (min-width: 64em) {
  .grid-y > .large-auto {
    flex: 1 1 0;
    height: auto;
  }
  .grid-y > .large-12, .grid-y > .large-11, .grid-y > .large-10, .grid-y > .large-9, .grid-y > .large-8, .grid-y > .large-7, .grid-y > .large-6, .grid-y > .large-5, .grid-y > .large-4, .grid-y > .large-3, .grid-y > .large-2, .grid-y > .large-1, .grid-y > .large-shrink {
    flex: 0 0 auto;
  }
  .grid-y > .large-shrink {
    height: auto;
  }
  .grid-y > .large-1 {
    height: 8.3333333333%;
  }
  .grid-y > .large-2 {
    height: 16.6666666667%;
  }
  .grid-y > .large-3 {
    height: 25%;
  }
  .grid-y > .large-4 {
    height: 33.3333333333%;
  }
  .grid-y > .large-5 {
    height: 41.6666666667%;
  }
  .grid-y > .large-6 {
    height: 50%;
  }
  .grid-y > .large-7 {
    height: 58.3333333333%;
  }
  .grid-y > .large-8 {
    height: 66.6666666667%;
  }
  .grid-y > .large-9 {
    height: 75%;
  }
  .grid-y > .large-10 {
    height: 83.3333333333%;
  }
  .grid-y > .large-11 {
    height: 91.6666666667%;
  }
  .grid-y > .large-12 {
    height: 100%;
  }
}

.grid-padding-y .grid-padding-y {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem;
}
@media print, screen and (min-width: 40em) {
  .grid-padding-y .grid-padding-y {
    margin-top: -0.9375rem;
    margin-bottom: -0.9375rem;
  }
}
.grid-padding-y > .cell {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
@media print, screen and (min-width: 40em) {
  .grid-padding-y > .cell {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
  }
}

.grid-margin-y {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem;
}
@media print, screen and (min-width: 40em) {
  .grid-margin-y {
    margin-top: -0.9375rem;
    margin-bottom: -0.9375rem;
  }
}
.grid-margin-y > .cell {
  height: calc(100% - 1.25rem);
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
@media print, screen and (min-width: 40em) {
  .grid-margin-y > .cell {
    height: calc(100% - 1.875rem);
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
  }
}
.grid-margin-y > .auto {
  height: auto;
}
.grid-margin-y > .shrink {
  height: auto;
}
.grid-margin-y > .small-1 {
  height: calc(8.3333333333% - 1.25rem);
}
.grid-margin-y > .small-2 {
  height: calc(16.6666666667% - 1.25rem);
}
.grid-margin-y > .small-3 {
  height: calc(25% - 1.25rem);
}
.grid-margin-y > .small-4 {
  height: calc(33.3333333333% - 1.25rem);
}
.grid-margin-y > .small-5 {
  height: calc(41.6666666667% - 1.25rem);
}
.grid-margin-y > .small-6 {
  height: calc(50% - 1.25rem);
}
.grid-margin-y > .small-7 {
  height: calc(58.3333333333% - 1.25rem);
}
.grid-margin-y > .small-8 {
  height: calc(66.6666666667% - 1.25rem);
}
.grid-margin-y > .small-9 {
  height: calc(75% - 1.25rem);
}
.grid-margin-y > .small-10 {
  height: calc(83.3333333333% - 1.25rem);
}
.grid-margin-y > .small-11 {
  height: calc(91.6666666667% - 1.25rem);
}
.grid-margin-y > .small-12 {
  height: calc(100% - 1.25rem);
}
@media print, screen and (min-width: 40em) {
  .grid-margin-y > .auto {
    height: auto;
  }
  .grid-margin-y > .shrink {
    height: auto;
  }
  .grid-margin-y > .small-1 {
    height: calc(8.3333333333% - 1.875rem);
  }
  .grid-margin-y > .small-2 {
    height: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.875rem);
  }
  .grid-margin-y > .small-4 {
    height: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-y > .small-5 {
    height: calc(41.6666666667% - 1.875rem);
  }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.875rem);
  }
  .grid-margin-y > .small-7 {
    height: calc(58.3333333333% - 1.875rem);
  }
  .grid-margin-y > .small-8 {
    height: calc(66.6666666667% - 1.875rem);
  }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.875rem);
  }
  .grid-margin-y > .small-10 {
    height: calc(83.3333333333% - 1.875rem);
  }
  .grid-margin-y > .small-11 {
    height: calc(91.6666666667% - 1.875rem);
  }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.875rem);
  }
  .grid-margin-y > .medium-auto {
    height: auto;
  }
  .grid-margin-y > .medium-shrink {
    height: auto;
  }
  .grid-margin-y > .medium-1 {
    height: calc(8.3333333333% - 1.875rem);
  }
  .grid-margin-y > .medium-2 {
    height: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-y > .medium-3 {
    height: calc(25% - 1.875rem);
  }
  .grid-margin-y > .medium-4 {
    height: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-y > .medium-5 {
    height: calc(41.6666666667% - 1.875rem);
  }
  .grid-margin-y > .medium-6 {
    height: calc(50% - 1.875rem);
  }
  .grid-margin-y > .medium-7 {
    height: calc(58.3333333333% - 1.875rem);
  }
  .grid-margin-y > .medium-8 {
    height: calc(66.6666666667% - 1.875rem);
  }
  .grid-margin-y > .medium-9 {
    height: calc(75% - 1.875rem);
  }
  .grid-margin-y > .medium-10 {
    height: calc(83.3333333333% - 1.875rem);
  }
  .grid-margin-y > .medium-11 {
    height: calc(91.6666666667% - 1.875rem);
  }
  .grid-margin-y > .medium-12 {
    height: calc(100% - 1.875rem);
  }
}
@media print, screen and (min-width: 64em) {
  .grid-margin-y > .large-auto {
    height: auto;
  }
  .grid-margin-y > .large-shrink {
    height: auto;
  }
  .grid-margin-y > .large-1 {
    height: calc(8.3333333333% - 1.875rem);
  }
  .grid-margin-y > .large-2 {
    height: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-y > .large-3 {
    height: calc(25% - 1.875rem);
  }
  .grid-margin-y > .large-4 {
    height: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-y > .large-5 {
    height: calc(41.6666666667% - 1.875rem);
  }
  .grid-margin-y > .large-6 {
    height: calc(50% - 1.875rem);
  }
  .grid-margin-y > .large-7 {
    height: calc(58.3333333333% - 1.875rem);
  }
  .grid-margin-y > .large-8 {
    height: calc(66.6666666667% - 1.875rem);
  }
  .grid-margin-y > .large-9 {
    height: calc(75% - 1.875rem);
  }
  .grid-margin-y > .large-10 {
    height: calc(83.3333333333% - 1.875rem);
  }
  .grid-margin-y > .large-11 {
    height: calc(91.6666666667% - 1.875rem);
  }
  .grid-margin-y > .large-12 {
    height: calc(100% - 1.875rem);
  }
}

.grid-frame {
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100vw;
}

.cell .grid-frame {
  width: 100%;
}

.cell-block {
  overflow-x: auto;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.cell-block-y {
  overflow-y: auto;
  max-height: 100%;
  min-height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.cell-block-container {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}
.cell-block-container > .grid-x {
  max-height: 100%;
  flex-wrap: nowrap;
}

@media print, screen and (min-width: 40em) {
  .medium-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw;
  }

  .cell .medium-grid-frame {
    width: 100%;
  }

  .medium-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .medium-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }
  .medium-cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap;
  }

  .medium-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    min-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}
@media print, screen and (min-width: 64em) {
  .large-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw;
  }

  .cell .large-grid-frame {
    width: 100%;
  }

  .large-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .large-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }
  .large-cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap;
  }

  .large-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    min-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}
.grid-y.grid-frame {
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100vh;
  width: auto;
}
@media print, screen and (min-width: 40em) {
  .grid-y.medium-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh;
    width: auto;
  }
}
@media print, screen and (min-width: 64em) {
  .grid-y.large-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh;
    width: auto;
  }
}

.cell .grid-y.grid-frame {
  height: 100%;
}
@media print, screen and (min-width: 40em) {
  .cell .grid-y.medium-grid-frame {
    height: 100%;
  }
}
@media print, screen and (min-width: 64em) {
  .cell .grid-y.large-grid-frame {
    height: 100%;
  }
}

.grid-margin-y {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem;
}
@media print, screen and (min-width: 40em) {
  .grid-margin-y {
    margin-top: -0.9375rem;
    margin-bottom: -0.9375rem;
  }
}
.grid-margin-y > .cell {
  height: calc(100% - 1.25rem);
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
@media print, screen and (min-width: 40em) {
  .grid-margin-y > .cell {
    height: calc(100% - 1.875rem);
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
  }
}
.grid-margin-y > .auto {
  height: auto;
}
.grid-margin-y > .shrink {
  height: auto;
}
.grid-margin-y > .small-1 {
  height: calc(8.3333333333% - 1.25rem);
}
.grid-margin-y > .small-2 {
  height: calc(16.6666666667% - 1.25rem);
}
.grid-margin-y > .small-3 {
  height: calc(25% - 1.25rem);
}
.grid-margin-y > .small-4 {
  height: calc(33.3333333333% - 1.25rem);
}
.grid-margin-y > .small-5 {
  height: calc(41.6666666667% - 1.25rem);
}
.grid-margin-y > .small-6 {
  height: calc(50% - 1.25rem);
}
.grid-margin-y > .small-7 {
  height: calc(58.3333333333% - 1.25rem);
}
.grid-margin-y > .small-8 {
  height: calc(66.6666666667% - 1.25rem);
}
.grid-margin-y > .small-9 {
  height: calc(75% - 1.25rem);
}
.grid-margin-y > .small-10 {
  height: calc(83.3333333333% - 1.25rem);
}
.grid-margin-y > .small-11 {
  height: calc(91.6666666667% - 1.25rem);
}
.grid-margin-y > .small-12 {
  height: calc(100% - 1.25rem);
}
@media print, screen and (min-width: 40em) {
  .grid-margin-y > .auto {
    height: auto;
  }
  .grid-margin-y > .shrink {
    height: auto;
  }
  .grid-margin-y > .small-1 {
    height: calc(8.3333333333% - 1.875rem);
  }
  .grid-margin-y > .small-2 {
    height: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.875rem);
  }
  .grid-margin-y > .small-4 {
    height: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-y > .small-5 {
    height: calc(41.6666666667% - 1.875rem);
  }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.875rem);
  }
  .grid-margin-y > .small-7 {
    height: calc(58.3333333333% - 1.875rem);
  }
  .grid-margin-y > .small-8 {
    height: calc(66.6666666667% - 1.875rem);
  }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.875rem);
  }
  .grid-margin-y > .small-10 {
    height: calc(83.3333333333% - 1.875rem);
  }
  .grid-margin-y > .small-11 {
    height: calc(91.6666666667% - 1.875rem);
  }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.875rem);
  }
  .grid-margin-y > .medium-auto {
    height: auto;
  }
  .grid-margin-y > .medium-shrink {
    height: auto;
  }
  .grid-margin-y > .medium-1 {
    height: calc(8.3333333333% - 1.875rem);
  }
  .grid-margin-y > .medium-2 {
    height: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-y > .medium-3 {
    height: calc(25% - 1.875rem);
  }
  .grid-margin-y > .medium-4 {
    height: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-y > .medium-5 {
    height: calc(41.6666666667% - 1.875rem);
  }
  .grid-margin-y > .medium-6 {
    height: calc(50% - 1.875rem);
  }
  .grid-margin-y > .medium-7 {
    height: calc(58.3333333333% - 1.875rem);
  }
  .grid-margin-y > .medium-8 {
    height: calc(66.6666666667% - 1.875rem);
  }
  .grid-margin-y > .medium-9 {
    height: calc(75% - 1.875rem);
  }
  .grid-margin-y > .medium-10 {
    height: calc(83.3333333333% - 1.875rem);
  }
  .grid-margin-y > .medium-11 {
    height: calc(91.6666666667% - 1.875rem);
  }
  .grid-margin-y > .medium-12 {
    height: calc(100% - 1.875rem);
  }
}
@media print, screen and (min-width: 64em) {
  .grid-margin-y > .large-auto {
    height: auto;
  }
  .grid-margin-y > .large-shrink {
    height: auto;
  }
  .grid-margin-y > .large-1 {
    height: calc(8.3333333333% - 1.875rem);
  }
  .grid-margin-y > .large-2 {
    height: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-y > .large-3 {
    height: calc(25% - 1.875rem);
  }
  .grid-margin-y > .large-4 {
    height: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-y > .large-5 {
    height: calc(41.6666666667% - 1.875rem);
  }
  .grid-margin-y > .large-6 {
    height: calc(50% - 1.875rem);
  }
  .grid-margin-y > .large-7 {
    height: calc(58.3333333333% - 1.875rem);
  }
  .grid-margin-y > .large-8 {
    height: calc(66.6666666667% - 1.875rem);
  }
  .grid-margin-y > .large-9 {
    height: calc(75% - 1.875rem);
  }
  .grid-margin-y > .large-10 {
    height: calc(83.3333333333% - 1.875rem);
  }
  .grid-margin-y > .large-11 {
    height: calc(91.6666666667% - 1.875rem);
  }
  .grid-margin-y > .large-12 {
    height: calc(100% - 1.875rem);
  }
}

.grid-frame.grid-margin-y {
  height: calc(100vh + 1.25rem);
}
@media print, screen and (min-width: 40em) {
  .grid-frame.grid-margin-y {
    height: calc(100vh + 1.875rem);
  }
}
@media print, screen and (min-width: 64em) {
  .grid-frame.grid-margin-y {
    height: calc(100vh + 1.875rem);
  }
}

@media print, screen and (min-width: 40em) {
  .grid-margin-y.medium-grid-frame {
    height: calc(100vh + 1.875rem);
  }
}
@media print, screen and (min-width: 64em) {
  .grid-margin-y.large-grid-frame {
    height: calc(100vh + 1.875rem);
  }
}
/* ===============================
=            Choices            =
=============================== */
.choices {
  position: relative;
  overflow: hidden;
  margin-bottom: 24px;
  font-size: 16px;
}
.choices:focus {
  outline: none;
}
.choices:last-child {
  margin-bottom: 0;
}
.choices.is-open {
  overflow: initial;
}
.choices.is-disabled .choices__inner,
.choices.is-disabled .choices__input {
  background-color: #eaeaea;
  cursor: not-allowed;
  user-select: none;
}
.choices.is-disabled .choices__item {
  cursor: not-allowed;
}
.choices [hidden] {
  display: none !important;
}

.choices[data-type*=select-one] {
  cursor: pointer;
}
.choices[data-type*=select-one] .choices__inner {
  padding-bottom: 7.5px;
}
.choices[data-type*=select-one] .choices__input {
  display: block;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  margin: 0;
}
.choices[data-type*=select-one] .choices__button {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
  padding: 0;
  background-size: 8px;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -10px;
  margin-right: 25px;
  height: 20px;
  width: 20px;
  border-radius: 10em;
  opacity: 0.25;
}
.choices[data-type*=select-one] .choices__button:hover, .choices[data-type*=select-one] .choices__button:focus {
  opacity: 1;
}
.choices[data-type*=select-one] .choices__button:focus {
  box-shadow: 0 0 0 2px #00bcd4;
}
.choices[data-type*=select-one] .choices__item[data-value=""] .choices__button {
  display: none;
}
.choices[data-type*=select-one]::after {
  content: "";
  height: 0;
  width: 0;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
  border-width: 5px;
  position: absolute;
  right: 11.5px;
  top: 50%;
  margin-top: -2.5px;
  pointer-events: none;
}
.choices[data-type*=select-one].is-open::after {
  border-color: transparent transparent #333 transparent;
  margin-top: -7.5px;
}
.choices[data-type*=select-one][dir=rtl]::after {
  left: 11.5px;
  right: auto;
}
.choices[data-type*=select-one][dir=rtl] .choices__button {
  right: auto;
  left: 0;
  margin-left: 25px;
  margin-right: 0;
}

.choices[data-type*=select-multiple] .choices__inner,
.choices[data-type*=text] .choices__inner {
  cursor: text;
}
.choices[data-type*=select-multiple] .choices__button,
.choices[data-type*=text] .choices__button {
  position: relative;
  display: inline-block;
  margin-top: 0;
  margin-right: -4px;
  margin-bottom: 0;
  margin-left: 8px;
  padding-left: 16px;
  border-left: 1px solid #008fa1;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
  background-size: 8px;
  width: 8px;
  line-height: 1;
  opacity: 0.75;
  border-radius: 0;
}
.choices[data-type*=select-multiple] .choices__button:hover, .choices[data-type*=select-multiple] .choices__button:focus,
.choices[data-type*=text] .choices__button:hover,
.choices[data-type*=text] .choices__button:focus {
  opacity: 1;
}

.choices__inner {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background-color: #f9f9f9;
  padding: 7.5px 7.5px 3.75px;
  border: 1px solid #ddd;
  border-radius: 2.5px;
  font-size: 14px;
  min-height: 44px;
  overflow: hidden;
}
.is-focused .choices__inner, .is-open .choices__inner {
  border-color: #b7b7b7;
}
.is-open .choices__inner {
  border-radius: 2.5px 2.5px 0 0;
}
.is-flipped.is-open .choices__inner {
  border-radius: 0 0 2.5px 2.5px;
}

.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.choices__list--single {
  display: inline-block;
  padding: 4px 16px 4px 4px;
  width: 100%;
}
[dir=rtl] .choices__list--single {
  padding-right: 4px;
  padding-left: 16px;
}
.choices__list--single .choices__item {
  width: 100%;
}

.choices__list--multiple {
  display: inline;
}
.choices__list--multiple .choices__item {
  display: inline-block;
  vertical-align: middle;
  border-radius: 20px;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 3.75px;
  margin-bottom: 3.75px;
  background-color: #00bcd4;
  border: 1px solid #00a5bb;
  color: #fff;
  word-break: break-all;
  box-sizing: border-box;
}
.choices__list--multiple .choices__item[data-deletable] {
  padding-right: 5px;
}
[dir=rtl] .choices__list--multiple .choices__item {
  margin-right: 0;
  margin-left: 3.75px;
}
.choices__list--multiple .choices__item.is-highlighted {
  background-color: #00a5bb;
  border: 1px solid #008fa1;
}
.is-disabled .choices__list--multiple .choices__item {
  background-color: #aaaaaa;
  border: 1px solid #919191;
}

.choices__list--dropdown {
  visibility: hidden;
  z-index: 1;
  position: absolute;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  top: 100%;
  margin-top: -1px;
  border-bottom-left-radius: 2.5px;
  border-bottom-right-radius: 2.5px;
  overflow: hidden;
  word-break: break-all;
  will-change: visibility;
}
.choices__list--dropdown.is-active {
  visibility: visible;
}
.is-open .choices__list--dropdown {
  border-color: #b7b7b7;
}
.is-flipped .choices__list--dropdown {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: -1px;
  border-radius: 0.25rem 0.25rem 0 0;
}
.choices__list--dropdown .choices__list {
  position: relative;
  max-height: 300px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
}
.choices__list--dropdown .choices__item {
  position: relative;
  padding: 10px;
  font-size: 14px;
}
[dir=rtl] .choices__list--dropdown .choices__item {
  text-align: right;
}
@media (min-width: 640px) {
  .choices__list--dropdown .choices__item--selectable {
    padding-right: 100px;
  }
  .choices__list--dropdown .choices__item--selectable::after {
    content: attr(data-select-text);
    font-size: 12px;
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  [dir=rtl] .choices__list--dropdown .choices__item--selectable {
    text-align: right;
    padding-left: 100px;
    padding-right: 10px;
  }
  [dir=rtl] .choices__list--dropdown .choices__item--selectable::after {
    right: auto;
    left: 10px;
  }
}
.choices__list--dropdown .choices__item--selectable.is-highlighted {
  background-color: #f2f2f2;
}
.choices__list--dropdown .choices__item--selectable.is-highlighted::after {
  opacity: 0.5;
}

.choices__item {
  cursor: default;
}

.choices__item--selectable {
  cursor: pointer;
}

.choices__item--disabled {
  cursor: not-allowed;
  user-select: none;
  opacity: 0.5;
}

.choices__heading {
  font-weight: 600;
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px solid #f7f7f7;
  color: gray;
}

.choices__button {
  text-indent: -9999px;
  appearance: none;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.choices__button:focus {
  outline: none;
}

.choices__input {
  display: inline-block;
  vertical-align: baseline;
  background-color: #f9f9f9;
  font-size: 14px;
  margin-bottom: 5px;
  border: 0;
  border-radius: 0;
  max-width: 100%;
  padding: 4px 0 4px 2px;
}
.choices__input:focus {
  outline: 0;
}
[dir=rtl] .choices__input {
  padding-right: 2px;
  padding-left: 0;
}

.choices__placeholder {
  opacity: 0.5;
}

/* =====  End of Choices  ====== */
[data-sal] {
  transition-delay: 0s;
  transition-delay: var(--sal-delay, 0s);
  transition-duration: 0.2s;
  transition-duration: var(--sal-duration, 0.2s);
  transition-timing-function: ease;
  transition-timing-function: var(--sal-easing, ease);
}

[data-sal][data-sal-duration="200"] {
  transition-duration: 0.2s;
}

[data-sal][data-sal-duration="250"] {
  transition-duration: 0.25s;
}

[data-sal][data-sal-duration="300"] {
  transition-duration: 0.3s;
}

[data-sal][data-sal-duration="350"] {
  transition-duration: 0.35s;
}

[data-sal][data-sal-duration="400"] {
  transition-duration: 0.4s;
}

[data-sal][data-sal-duration="450"] {
  transition-duration: 0.45s;
}

[data-sal][data-sal-duration="500"] {
  transition-duration: 0.5s;
}

[data-sal][data-sal-duration="550"] {
  transition-duration: 0.55s;
}

[data-sal][data-sal-duration="600"] {
  transition-duration: 0.6s;
}

[data-sal][data-sal-duration="650"] {
  transition-duration: 0.65s;
}

[data-sal][data-sal-duration="700"] {
  transition-duration: 0.7s;
}

[data-sal][data-sal-duration="750"] {
  transition-duration: 0.75s;
}

[data-sal][data-sal-duration="800"] {
  transition-duration: 0.8s;
}

[data-sal][data-sal-duration="850"] {
  transition-duration: 0.85s;
}

[data-sal][data-sal-duration="900"] {
  transition-duration: 0.9s;
}

[data-sal][data-sal-duration="950"] {
  transition-duration: 0.95s;
}

[data-sal][data-sal-duration="1000"] {
  transition-duration: 1s;
}

[data-sal][data-sal-duration="1050"] {
  transition-duration: 1.05s;
}

[data-sal][data-sal-duration="1100"] {
  transition-duration: 1.1s;
}

[data-sal][data-sal-duration="1150"] {
  transition-duration: 1.15s;
}

[data-sal][data-sal-duration="1200"] {
  transition-duration: 1.2s;
}

[data-sal][data-sal-duration="1250"] {
  transition-duration: 1.25s;
}

[data-sal][data-sal-duration="1300"] {
  transition-duration: 1.3s;
}

[data-sal][data-sal-duration="1350"] {
  transition-duration: 1.35s;
}

[data-sal][data-sal-duration="1400"] {
  transition-duration: 1.4s;
}

[data-sal][data-sal-duration="1450"] {
  transition-duration: 1.45s;
}

[data-sal][data-sal-duration="1500"] {
  transition-duration: 1.5s;
}

[data-sal][data-sal-duration="1550"] {
  transition-duration: 1.55s;
}

[data-sal][data-sal-duration="1600"] {
  transition-duration: 1.6s;
}

[data-sal][data-sal-duration="1650"] {
  transition-duration: 1.65s;
}

[data-sal][data-sal-duration="1700"] {
  transition-duration: 1.7s;
}

[data-sal][data-sal-duration="1750"] {
  transition-duration: 1.75s;
}

[data-sal][data-sal-duration="1800"] {
  transition-duration: 1.8s;
}

[data-sal][data-sal-duration="1850"] {
  transition-duration: 1.85s;
}

[data-sal][data-sal-duration="1900"] {
  transition-duration: 1.9s;
}

[data-sal][data-sal-duration="1950"] {
  transition-duration: 1.95s;
}

[data-sal][data-sal-duration="2000"] {
  transition-duration: 2s;
}

[data-sal][data-sal-delay="50"] {
  transition-delay: 0.05s;
}

[data-sal][data-sal-delay="100"] {
  transition-delay: 0.1s;
}

[data-sal][data-sal-delay="150"] {
  transition-delay: 0.15s;
}

[data-sal][data-sal-delay="200"] {
  transition-delay: 0.2s;
}

[data-sal][data-sal-delay="250"] {
  transition-delay: 0.25s;
}

[data-sal][data-sal-delay="300"] {
  transition-delay: 0.3s;
}

[data-sal][data-sal-delay="350"] {
  transition-delay: 0.35s;
}

[data-sal][data-sal-delay="400"] {
  transition-delay: 0.4s;
}

[data-sal][data-sal-delay="450"] {
  transition-delay: 0.45s;
}

[data-sal][data-sal-delay="500"] {
  transition-delay: 0.5s;
}

[data-sal][data-sal-delay="550"] {
  transition-delay: 0.55s;
}

[data-sal][data-sal-delay="600"] {
  transition-delay: 0.6s;
}

[data-sal][data-sal-delay="650"] {
  transition-delay: 0.65s;
}

[data-sal][data-sal-delay="700"] {
  transition-delay: 0.7s;
}

[data-sal][data-sal-delay="750"] {
  transition-delay: 0.75s;
}

[data-sal][data-sal-delay="800"] {
  transition-delay: 0.8s;
}

[data-sal][data-sal-delay="850"] {
  transition-delay: 0.85s;
}

[data-sal][data-sal-delay="900"] {
  transition-delay: 0.9s;
}

[data-sal][data-sal-delay="950"] {
  transition-delay: 0.95s;
}

[data-sal][data-sal-delay="1000"] {
  transition-delay: 1s;
}

[data-sal][data-sal-easing=linear] {
  transition-timing-function: linear;
}

[data-sal][data-sal-easing=ease] {
  transition-timing-function: ease;
}

[data-sal][data-sal-easing=ease-in] {
  transition-timing-function: ease-in;
}

[data-sal][data-sal-easing=ease-out] {
  transition-timing-function: ease-out;
}

[data-sal][data-sal-easing=ease-in-out] {
  transition-timing-function: ease-in-out;
}

[data-sal][data-sal-easing=ease-in-cubic] {
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

[data-sal][data-sal-easing=ease-out-cubic] {
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

[data-sal][data-sal-easing=ease-in-out-cubic] {
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

[data-sal][data-sal-easing=ease-in-circ] {
  transition-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.335);
}

[data-sal][data-sal-easing=ease-out-circ] {
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

[data-sal][data-sal-easing=ease-in-out-circ] {
  transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

[data-sal][data-sal-easing=ease-in-expo] {
  transition-timing-function: cubic-bezier(0.95, 0.05, 0.795, 0.035);
}

[data-sal][data-sal-easing=ease-out-expo] {
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

[data-sal][data-sal-easing=ease-in-out-expo] {
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
}

[data-sal][data-sal-easing=ease-in-quad] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-sal][data-sal-easing=ease-out-quad] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-sal][data-sal-easing=ease-in-out-quad] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-sal][data-sal-easing=ease-in-quart] {
  transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

[data-sal][data-sal-easing=ease-out-quart] {
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

[data-sal][data-sal-easing=ease-in-out-quart] {
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}

[data-sal][data-sal-easing=ease-in-quint] {
  transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

[data-sal][data-sal-easing=ease-out-quint] {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

[data-sal][data-sal-easing=ease-in-out-quint] {
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}

[data-sal][data-sal-easing=ease-in-sine] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

[data-sal][data-sal-easing=ease-out-sine] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

[data-sal][data-sal-easing=ease-in-out-sine] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-sal][data-sal-easing=ease-in-back] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

[data-sal][data-sal-easing=ease-out-back] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

[data-sal][data-sal-easing=ease-in-out-back] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

[data-sal|=fade] {
  opacity: 0;
  transition-property: opacity;
}

[data-sal|=fade].sal-animate, body.sal-disabled [data-sal|=fade] {
  opacity: 1;
}

[data-sal|=slide] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-sal=slide-up] {
  transform: translateY(20%);
}

[data-sal=slide-down] {
  transform: translateY(-20%);
}

[data-sal=slide-left] {
  transform: translateX(20%);
}

[data-sal=slide-right] {
  transform: translateX(-20%);
}

[data-sal|=slide].sal-animate, body.sal-disabled [data-sal|=slide] {
  opacity: 1;
  transform: none;
}

[data-sal|=zoom] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-sal=zoom-in] {
  transform: scale(0.5);
}

[data-sal=zoom-out] {
  transform: scale(1.1);
}

[data-sal|=zoom].sal-animate, body.sal-disabled [data-sal|=zoom] {
  opacity: 1;
  transform: none;
}

[data-sal|=flip] {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition-property: transform;
}

[data-sal=flip-left] {
  transform: perspective(2000px) rotateY(-91deg);
}

[data-sal=flip-right] {
  transform: perspective(2000px) rotateY(91deg);
}

[data-sal=flip-up] {
  transform: perspective(2000px) rotateX(-91deg);
}

[data-sal=flip-down] {
  transform: perspective(2000px) rotateX(91deg);
}

[data-sal|=flip].sal-animate, body.sal-disabled [data-sal|=flip] {
  transform: none;
}

/*# sourceMappingURL=sal.css.map*/
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: "";
  content: none;
}

q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  color: #4b0e20;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  margin-bottom: 0 !important;
}
@media screen and (min-width: 80em) {
  body {
    font-size: 16px;
    font-size: 1rem;
  }
}

img {
  max-width: 100%;
  height: auto;
}

strong {
  font-weight: 600;
}

em {
  font-style: italic;
}

picture {
  display: block;
  overflow: hidden;
}
picture img {
  display: inline-block;
  margin: auto;
  width: 100%;
  filter: blur(10px);
  transition: transform 0.15s, filter 0.15s;
}
picture img.lazyloaded {
  filter: blur(0px);
}

[data-sal] {
  transition-duration: 0.75s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

[data-sal|=slide-up] {
  transform: translateY(40px);
}
@media print, screen and (min-width: 64em) {
  [data-sal|=slide-up] {
    transform: translateY(60px);
  }
}

[data-sal|=slide-up-fat] {
  transform: translateY(60px);
}
@media print, screen and (min-width: 64em) {
  [data-sal|=slide-up-fat] {
    transform: translateY(80px);
  }
}

.menu-local-tasks ul li:first-child {
  display: none;
}

.menu-local-tasks ul li {
  margin-bottom: 10px;
}

.tabs {
  position: fixed;
  bottom: 0;
  left: 0;
  color: #547539;
  z-index: 99;
  background: #e9660a;
  border-radius: 0 3px 0 0;
}
.tabs li {
  display: inline;
}
.tabs a {
  display: inline-block;
  padding: 8px 12px 6px;
  font-size: 12px;
  font-size: 0.75rem;
  color: #547539;
  text-decoration: none;
}
.tabs a:hover, .tabs a.active {
  border-bottom: 2px solid #547539;
}

.element-invisible {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}

.element-invisible.element-focusable:active, .element-invisible.element-focusable:focus {
  position: static !important;
  clip: auto;
  overflow: visible;
  height: auto;
}

.contentinfo {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: #547539;
  z-index: 99;
  padding: 20px;
  box-sizing: border-box;
  color: #fff;
  max-width: 500px;
  text-align: center;
  cursor: pointer;
  line-height: 1.5em;
  transition: opacity 0.15s;
}
@media print, screen and (min-width: 64em) {
  .contentinfo {
    padding: 20px 50px;
    bottom: 20px;
    font-size: 17px;
    font-size: 1.0625rem;
  }
}
.contentinfo--error {
  background: #de3b2f;
}
.contentinfo--hidden {
  opacity: 0;
  pointer-events: none;
}
.contentinfo:after {
  content: "✕";
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  width: 22px;
  height: 22px;
  font-size: 16px;
  font-size: 1rem;
  text-align: center;
  line-height: 22px;
  color: #fff;
}
.contentinfo a {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
}

.form-item-view-mode {
  display: none !important;
}

a#edit-backlink, .menu-local-tasks ul li a {
  display: none;
}
@media print, screen and (min-width: 64em) {
  a#edit-backlink, .menu-local-tasks ul li a {
    display: inline-block;
    background: #041bb9;
    padding: 10px 25px;
    border-radius: 22px;
    border: 1px solid white;
    color: white;
    text-decoration: none;
    font-weight: 600;
  }
  a#edit-backlink:hover, .menu-local-tasks ul li a:hover {
    background: #031487;
  }
}

#toolbar-administration {
  display: none;
}
@media print, screen and (min-width: 64em) {
  #toolbar-administration {
    display: block;
  }
}

body.toolbar-tray-open.toolbar-vertical.toolbar-fixed {
  margin-left: 0 !important;
}
@media print, screen and (min-width: 64em) {
  body.toolbar-tray-open.toolbar-vertical.toolbar-fixed {
    margin-left: 240px !important;
    margin-left: 15rem !important;
  }
}

.adminimal-admin-toolbar nav.menu-local-tasks {
  position: fixed;
  z-index: 499;
  box-sizing: border-box;
  width: 10%;
  padding: 10px;
  bottom: 0;
}

/*
//Browsers
ie,
edge,
chrome,
opera,
firefox,
safari,
vivaldi,

//Specific Browsers
chromeIOS,
ieMobile,

//Platforms
windows,
mac,
linux,
android,
blackberry,
ios,

//Type
desktop,
mobile,
*/
body.cke_editable {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  color: #4b0e20;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  margin: 20px !important;
}
@media screen and (min-width: 80em) {
  body.cke_editable {
    font-size: 16px;
    font-size: 1rem;
  }
}
body.cke_editable img, body.cke_editable picture {
  max-width: 100%;
  height: auto;
}
body.cke_editable strong {
  font-weight: 600;
}
body.cke_editable em {
  font-style: italic;
}
body.cke_editable iframe {
  max-width: 100%;
  margin: 30px auto;
  /*border-radius: 25px;*/
}
body.cke_editable p {
  line-height: 1.5em;
  margin-bottom: 20px;
}
body.cke_editable ul {
  list-style-type: square;
  margin-left: 1.25em;
  margin-bottom: 20px;
}
body.cke_editable li {
  margin-bottom: 5px;
}
body.cke_editable a:not(.a-btn) {
  color: #547539;
}
body.cke_editable strong {
  color: #547539;
}

.a-wrapper {
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}
@media print, screen and (min-width: 35.5em) {
  .a-wrapper {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
}
@media print, screen and (min-width: 48em) {
  .a-wrapper {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 22.5em) {
  .a-wrapper {
    padding-right: 10px;
    padding-left: 10px;
  }
}
@media screen and (min-width: 85.375em) {
  .a-wrapper {
    padding-right: 0;
    padding-left: 0;
  }
}

.a-heading {
  display: block;
  line-height: 1.2em;
  font-weight: 700;
  color: #547539;
}
.a-heading--1 {
  font-family: "Quattrocento Sans", sans-serif;
  font-size: 35px;
  font-size: 2.1875rem;
  margin-bottom: 20px;
}
@media print, screen and (min-width: 64em) {
  .a-heading--1 {
    font-size: 50px;
    font-size: 3.125rem;
  }
}
@media screen and (min-width: 85.375em) {
  .a-heading--1 {
    font-size: 61px;
    font-size: 3.8125rem;
  }
}
.a-heading--fat {
  font-family: "Quattrocento Sans", sans-serif;
  font-size: 35px;
  font-size: 2.1875rem;
  margin-bottom: 20px;
}
@media print, screen and (min-width: 64em) {
  .a-heading--fat {
    font-size: 46px;
    font-size: 2.875rem;
  }
}
.a-heading--2 {
  font-family: "Quattrocento Sans", sans-serif;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  color: #4b0e20;
  text-transform: uppercase;
  margin-top: 40px;
  margin-bottom: 30px;
}
@media print, screen and (min-width: 48em) {
  .a-heading--2 {
    font-size: 22px;
    font-size: 1.375rem;
  }
}
@media screen and (min-width: 85.375em) {
  .a-heading--2 {
    font-size: 26px;
    font-size: 1.625rem;
  }
}
.a-heading--3 {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 10px;
  line-height: 1.4em;
}
.a-heading--4 {
  color: #4b0e20;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
}
@media print, screen and (min-width: 64em) {
  .a-heading--4 {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
.a-heading--white {
  color: #fff;
}
.a-heading--primary {
  color: #547539;
}
.a-heading--secondary {
  color: #e9660a;
}
.a-heading--no-top {
  margin-top: 0;
}
.a-heading--no-bot {
  margin-bottom: 0;
}
.a-heading--centered {
  text-align: center;
}
.a-heading--slim {
  font-weight: 400;
}
.a-heading--background {
  padding: 0 5px;
}
.a-heading--background span {
  display: inline;
  background: #fff;
  box-shadow: 5px 1px 0 6px #fff, -5px 1px 0 6px #fff;
}
.a-heading__sub {
  display: block;
}

.a-text {
  line-height: 1.5em;
}
.a-text--primary {
  color: #547539;
}
.a-text--secondary {
  color: #e9660a;
}
.a-text--top {
  font-size: 18px;
  font-size: 1.125rem;
}
.a-text--fat {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 600;
}
@media screen and (min-width: 80em) {
  .a-text--fat {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
.a-text--ultra-fat {
  font-size: 22px;
  font-size: 1.375rem;
  font-weight: 600;
  text-shadow: 0 0 12px rgba(0, 0, 0, 0.5);
}
@media screen and (min-width: 80em) {
  .a-text--ultra-fat {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
.a-text--slim {
  font-size: 14px;
  font-size: 0.875rem;
}
.a-text--centered {
  text-align: center;
}
.a-text p {
  margin-bottom: 20px;
}
.a-text ul {
  list-style-type: square;
  margin-left: 1.25em;
  margin-bottom: 20px;
}
.a-text li {
  margin-bottom: 5px;
}
.a-text a:not(.a-btn) {
  color: #e9660a;
}
.a-text > *:first-child {
  margin-top: 0;
}
.a-text > *:last-child {
  margin-bottom: 0;
}
.a-text iframe {
  max-width: 100%;
}
.a-text--white {
  color: #fff;
}
.a-text--white a:not(.a-btn) {
  color: #fff;
}

.a-btn {
  display: inline-block;
  height: 44px;
  line-height: 42px;
  padding-right: 20px;
  padding-left: 60px;
  background: #547539;
  color: #fff;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  margin-top: 40px;
}
@media print, screen and (min-width: 48em) {
  .a-btn {
    height: 47px;
    line-height: 47px;
  }
}
@media print, screen and (min-width: 64em) {
  .a-btn {
    font-size: 16px;
    font-size: 1rem;
  }
}
@media screen and (min-width: 80em) {
  .a-btn {
    transition: background-color 0.15s;
  }
}
@media screen and (min-width: 80em) {
  .a-btn:hover {
    background-color: #5c9344;
  }
}
.a-btn:before {
  content: url(../img/arrow-white.svg);
  position: absolute;
  top: 50%;
  left: 20px;
  width: 20px;
  transform: translateY(-50%);
  transition: opacity 0.15s;
}
.a-btn--secondary {
  background-color: #e9660a;
}
@media screen and (min-width: 80em) {
  .a-btn--secondary:hover {
    background-color: #f68230;
  }
}
.a-btn--tertiary {
  background-color: #e9b344;
}
@media screen and (min-width: 80em) {
  .a-btn--tertiary:hover {
    background-color: #eec672;
  }
}
.a-btn--no-top {
  margin-top: 0;
}

.a-link {
  display: inline-block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 700;
  color: #e9660a;
  position: relative;
  outline: none;
  padding-top: 2px;
  padding-bottom: 2px;
}
@media screen and (min-width: 80em) {
  .a-link {
    transition: color 0.15s;
  }
}
@media screen and (min-width: 80em) {
  .a-link:hover {
    color: #f68230;
  }
}

.a-image img {
  display: inline-block;
  margin: auto;
}

.a-video__container {
  position: relative;
  z-index: 1;
  padding-bottom: 56.25%;
  margin: auto;
}
.a-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #e3e3e3;
}

.a-burger {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  position: relative;
  z-index: 4;
  height: 48px;
  width: 42px;
}
.a-burger__hexagon {
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 24.25px;
  background-color: #547539;
  margin: 12.12px 0;
}
.a-burger__hexagon:before, .a-burger__hexagon:after {
  content: "";
  position: absolute;
  width: 0;
  border-left: 21px solid transparent;
  border-right: 21px solid transparent;
}
.a-burger__hexagon:before {
  bottom: 100%;
  border-bottom: 12.12px solid #547539;
}
.a-burger__hexagon:after {
  top: 100%;
  width: 0;
  border-top: 12.12px solid #547539;
}
.a-burger__container {
  width: 18px;
  height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.a-burger__bar {
  position: absolute;
  top: 3px;
  width: 100%;
  height: 3px;
  background: #fff;
  transform-origin: left;
  transition: top 0.5s, bottom 0.5s, left 0.5s, transform 0.5s;
  transition-timing-function: cubic-bezier(0.77, 0.2, 0.05, 1);
}
.a-burger__bar:last-child {
  top: auto;
  bottom: 3px;
}

.o-body--menu-on .a-burger__bar {
  top: 0;
  transform: rotate(45deg);
}
.o-body--menu-on .a-burger__bar:last-child {
  top: auto;
  bottom: 0;
  transform: rotate(-45deg);
}

.a-leaves {
  position: relative;
}
.a-leaves--white .a-leaves__leaf {
  fill: #fff;
}
.a-leaves__leaf {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 32px;
  fill: #547539;
  opacity: 0;
  pointer-events: none;
}

.a-breadcrumb {
  margin: 10px 0 20px;
}
.a-breadcrumb__container {
  display: flex;
  flex-flow: row wrap;
}
.a-breadcrumb nav {
  flex: 0 0 auto;
  width: calc(100% - 1.25rem);
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  line-height: 1.2em;
}
@media screen and (min-width: 85.375em) {
  .a-breadcrumb nav {
    flex: 0 0 auto;
    width: calc(83.3333333333% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-left: calc(8.3333333333% + 1.25rem / 2);
  }
}
.a-breadcrumb li {
  display: inline-block;
  font-size: 12px;
  font-size: 0.75rem;
}
.a-breadcrumb li:after {
  content: ">";
  display: inline-block;
  margin: 0 5px;
  font-size: 12px;
  font-size: 0.75rem;
}
.a-breadcrumb li:last-child:after {
  display: none;
}
.a-breadcrumb a {
  color: #4b0e20;
  text-decoration: none;
}
@media screen and (min-width: 80em) {
  .a-breadcrumb a:hover {
    text-decoration: underline;
  }
}
.a-breadcrumb h2 {
  display: none;
}

.o-body--home .a-breadcrumb {
  display: none;
}

.a-go-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 3;
  width: 15px;
  height: 34px;
  transform: translateY(50%);
  opacity: 0;
  pointer-events: none;
  transition: transform 0.3s, opacity 0.3s;
}
@media print, screen and (min-width: 64em) {
  .a-go-top {
    width: 40px;
    height: 40px;
  }
}
.a-go-top:before {
  content: "";
  display: block;
  background-image: url(../img/arrow-tertiary.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 30px;
  height: 13px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
}

.o-body--long-scrolled .a-go-top {
  transform: translateY(0%);
  opacity: 1;
  pointer-events: auto;
}

.m-gallery {
  display: flex;
  flex-flow: row wrap;
  margin-right: -10px;
  margin-bottom: -20px;
  margin-left: -10px;
}
.m-gallery__item {
  flex: 0 0 auto;
  width: calc(50% - 1.25rem);
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  margin-bottom: 20px;
}
@media print, screen and (min-width: 48em) {
  .m-gallery__item {
    flex: 0 0 auto;
    width: calc(33.3333333333% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
}
.m-gallery__image {
  background: #e3e3e3;
}
.m-gallery img {
  display: block;
  width: 100%;
}

.m-cards {
  counter-reset: card;
}
@media print, screen and (min-width: 64em) {
  .m-cards {
    display: flex;
    flex-flow: row wrap;
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: -40px;
  }
}
.m-cards.swiper-container {
  overflow: visible;
}
.m-cards__card {
  max-width: 200px;
}
@media print, screen and (min-width: 64em) {
  .m-cards__card {
    flex: 0 0 auto;
    width: calc(25% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-bottom: 40px;
    max-width: none;
  }
}
@media print, screen and (min-width: 64em) {
  .m-cards__card:nth-child(3n+1) {
    margin-left: calc(8.3333333333% + 1.25rem / 2);
  }
}
@media print, screen and (min-width: 64em) {
  .m-cards__card:nth-child(3n+2) {
    flex: 0 0 auto;
    width: calc(33.3333333333% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    --sal-delay: 0.2s;
  }
}
@media print, screen and (min-width: 64em) {
  .m-cards__card:nth-child(3n+3) {
    --sal-delay: 0.4s;
  }
}
.m-cards__content {
  text-align: center;
  margin: auto;
}
@media print, screen and (min-width: 64em) {
  .m-cards__content {
    max-width: 310px;
  }
}
.m-cards__leaf {
  margin-bottom: 20px;
  position: relative;
}
.m-cards__leaf:before {
  counter-increment: card;
  content: counter(card);
  font-family: "Quattrocento Sans", sans-serif;
  font-size: 22px;
  font-size: 1.375rem;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.m-cards__leaf img {
  display: inline-block;
  width: 46px;
}

.m-view-jobs_list__rows {
  width: 100%;
}
.m-view-jobs_list__rows .a-heading--2 {
  margin-top: 60px;
}
@media screen and (min-width: 80em) {
  .m-view-jobs_list__rows .a-heading--2 {
    max-width: 1080px;
    margin-top: 80px;
    margin-right: auto;
    margin-left: auto;
  }
}
.m-view-jobs_list__rows .a-heading--2:first-child {
  margin-top: 0;
}
.m-view-jobs_list__container {
  height: 270px;
  overflow: visible;
}
@media print, screen and (min-width: 48em) {
  .m-view-jobs_list__container {
    display: flex;
    flex-flow: row wrap;
    margin-right: -10px;
    margin-bottom: -20px;
    margin-left: -10px;
    height: auto;
  }
}
.m-view-jobs_list__row {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  height: 270px;
  width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 40px 20px;
}
@media print, screen and (min-width: 48em) {
  .m-view-jobs_list__row {
    flex: 0 0 auto;
    width: calc(50% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    height: 470px;
    align-items: center;
    justify-content: center;
    padding: 40px;
    margin-bottom: 20px;
  }
}
@media print, screen and (min-width: 64em) {
  .m-view-jobs_list__row:nth-child(2n+2) {
    --sal-delay: 0.15s;
  }
}
@media screen and (min-width: 80em) {
  .m-view-jobs_list__row:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateY(-50%) scale(0);
    z-index: 1;
    opacity: 0;
    background: #5c4582;
    padding-bottom: 115%;
    clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
    transition: transform 0s 0.5s, opacity 0.5s;
  }
}
@media screen and (min-width: 80em) {
  .m-view-jobs_list__row:hover:after {
    transform: translateY(-50%) scale(1.2);
    opacity: 1;
    transition: transform 0.5s, opacity 0.5s;
  }
}
@media screen and (min-width: 80em) {
  .m-view-jobs_list__row:hover .m-view-jobs_list__leaves {
    opacity: 1;
    transition: opacity 0s;
  }
}
@media screen and (min-width: 80em) {
  .m-view-jobs_list__row:hover .a-heading {
    color: #fff;
  }
}
@media screen and (min-width: 80em) {
  .m-view-jobs_list__row:hover .a-heading span {
    box-shadow: 5px 1px 0 6px #5c4582, -5px 1px 0 6px #5c4582;
    background-color: #5c4582;
  }
}
.m-view-jobs_list__row img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (min-width: 80em) {
  .m-view-jobs_list__row .a-heading {
    transition: color 0.5s;
  }
}
@media screen and (min-width: 80em) {
  .m-view-jobs_list__row .a-heading span {
    transition: box-shadow 0.5s, background-color 0.5s;
  }
}
.m-view-jobs_list__title {
  position: relative;
  z-index: 2;
  color: #5c4582;
  text-align: center;
}
.m-view-jobs_list__link {
  z-index: 3;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.m-view-jobs_list__leaves {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.3s;
}

@media print, screen and (min-width: 64em) {
  .m-logos {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-right: -10px;
    margin-bottom: -10px;
    margin-left: -10px;
  }
}
.m-logos.swiper-container {
  overflow: visible;
}
.m-logos__logo {
  width: 200px;
}
@media print, screen and (min-width: 64em) {
  .m-logos__logo {
    flex: 0 0 auto;
    width: calc(12.5% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
}
.m-logos picture {
  clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  padding-bottom: 115%;
  position: relative;
  background: #f8f8f8;
}
.m-logos picture img {
  display: block;
  width: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 160px;
  height: 130px;
  max-width: 84%;
  max-height: 60%;
  mix-blend-mode: darken;
  object-fit: contain;
  image-rendering: -webkit-optimize-contrast;
}
.m-logos picture img .lazyloaded {
  transform: translate(-50%, -50%) scale(1);
}

.m-nav-main {
  order: 1;
  padding-left: 20px;
  padding-right: 40px;
}
@media screen and (min-width: 22.5em) {
  .m-nav-main {
    padding-left: 25px;
  }
}
@media print, screen and (min-width: 64em) {
  .m-nav-main {
    padding-left: 0;
    padding-right: 0;
  }
}
.m-nav-main__item {
  margin-bottom: 20px;
}
@media print, screen and (min-width: 64em) {
  .m-nav-main__item {
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 0;
    position: relative;
  }
}
@media screen and (min-width: 85.375em) {
  .m-nav-main__item {
    margin-right: 20px;
  }
}
@media print, screen and (min-width: 64em) {
  .m-nav-main__item:hover .m-nav-main__sub {
    pointer-events: auto;
    opacity: 1;
    transform: translate(-20px, 100%) scale(1);
    transition: transform 0.3s ease, opacity 0s;
  }
}
@media print, screen and (min-width: 64em) {
  .m-nav-main__item:hover .m-nav-main__sub-item {
    transform: translateY(0);
    opacity: 1;
  }
}
@media print, screen and (min-width: 64em) {
  .m-nav-main__item:hover .m-nav-main__sub-item:nth-child(1) {
    transition-delay: 100ms;
  }
}
@media print, screen and (min-width: 64em) {
  .m-nav-main__item:hover .m-nav-main__sub-item:nth-child(2) {
    transition-delay: 130ms;
  }
}
@media print, screen and (min-width: 64em) {
  .m-nav-main__item:hover .m-nav-main__sub-item:nth-child(3) {
    transition-delay: 160ms;
  }
}
@media print, screen and (min-width: 64em) {
  .m-nav-main__item:hover .m-nav-main__sub-item:nth-child(4) {
    transition-delay: 190ms;
  }
}
@media print, screen and (min-width: 64em) {
  .m-nav-main__item:hover .m-nav-main__sub-item:nth-child(5) {
    transition-delay: 220ms;
  }
}
@media print, screen and (min-width: 64em) {
  .m-nav-main__item:hover .m-nav-main__sub-item:nth-child(6) {
    transition-delay: 250ms;
  }
}
@media print, screen and (min-width: 64em) {
  .m-nav-main__item:hover .m-nav-main__sub-item:nth-child(7) {
    transition-delay: 280ms;
  }
}
@media print, screen and (min-width: 64em) {
  .m-nav-main__item:hover .m-nav-main__sub-item:nth-child(8) {
    transition-delay: 310ms;
  }
}
@media print, screen and (min-width: 64em) {
  .m-nav-main__item:hover .m-nav-main__sub-item:nth-child(9) {
    transition-delay: 340ms;
  }
}
@media print, screen and (min-width: 64em) {
  .m-nav-main__item:hover .m-nav-main__sub-item:nth-child(10) {
    transition-delay: 370ms;
  }
}
.m-nav-main__item:last-child {
  margin-bottom: 0;
}
@media print, screen and (min-width: 64em) {
  .m-nav-main__item:last-child {
    margin-right: 0;
  }
}
.m-nav-main__item--expanded .m-nav-main__link {
  position: relative;
}
@media print, screen and (min-width: 64em) {
  .m-nav-main__item--expanded .m-nav-main__link {
    padding-right: 15px;
  }
}
.m-nav-main__item--expanded .m-nav-main__link:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  height: 0;
  width: 0;
  border-top: 8px solid #e9b344;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
}
@media print, screen and (min-width: 64em) {
  .m-nav-main__item--expanded .m-nav-main__link:after {
    width: 6px;
    height: 6px;
    border: none;
    border-right: 1px solid #4b0e20;
    border-bottom: 1px solid #4b0e20;
    box-sizing: border-box;
    transform: translateY(-75%) rotate(45deg);
  }
}
.m-nav-main__item--expanded .m-nav-main__link.accordino__question--opened:after {
  transform: translateY(-50%) rotate(180deg);
}
.m-nav-main__item--active-trail .m-nav-main__link {
  color: #e9b344 !important;
}
.m-nav-main__link {
  display: block;
  font-family: "Quattrocento Sans", sans-serif;
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 1.3em;
  font-weight: 700;
  text-decoration: none;
  color: #fff;
  padding: 10px 0;
  border-bottom: 1px solid #fff;
}
@media print, screen and (min-width: 64em) {
  .m-nav-main__link {
    color: #4b0e20;
    font-size: 17px;
    font-size: 1.0625rem;
    border-bottom: none;
    padding: 15px 0;
  }
}
@media screen and (min-width: 80em) {
  .m-nav-main__link {
    transition: color 0.15s;
  }
}
@media screen and (min-width: 80em) {
  .m-nav-main__link:hover {
    color: #4b0e20;
  }
}
.m-nav-main__link.is-active {
  color: #e9b344;
}
.m-nav-main__sub {
  display: none;
}
@media print, screen and (min-width: 64em) {
  .m-nav-main__sub {
    display: block;
    position: absolute;
    z-index: 1;
    bottom: 0;
    background: #547539;
    text-align: left;
    min-width: calc(100% + 40px);
    transform-origin: left top;
    transform: translate(-20px, 100%) scaleY(0.001);
    opacity: 0;
    transition: transform 0.3s ease, opacity 0s 0.3s;
    overflow: hidden;
    pointer-events: none;
  }
}
.m-nav-main__sub:before {
  content: "";
  display: block;
  height: 10px;
}
@media print, screen and (min-width: 64em) {
  .m-nav-main__sub:before {
    display: none;
  }
}
.m-nav-main__sub-item {
  margin-bottom: 5px;
}
@media print, screen and (min-width: 64em) {
  .m-nav-main__sub-item {
    margin-bottom: 0;
    transform: translateY(-20px);
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
}
.m-nav-main__sub-item:last-child {
  margin-bottom: 0;
}
.m-nav-main__sub-link {
  display: block;
  font-size: 16px;
  font-size: 1rem;
  color: #fff;
  text-decoration: none;
  font-weight: 400;
  padding: 5px 0;
}
@media print, screen and (min-width: 64em) {
  .m-nav-main__sub-link {
    font-family: "Quattrocento Sans", sans-serif;
    font-size: 17px;
    font-size: 1.0625rem;
    padding: 15px 20px;
    white-space: nowrap;
  }
}
@media screen and (min-width: 80em) {
  .m-nav-main__sub-link {
    transition: background-color 0.15s;
  }
}
@media screen and (min-width: 80em) {
  .m-nav-main__sub-link:hover {
    background: #5c9344;
  }
}
.m-nav-main__sub-link.is-active {
  text-decoration: underline;
}

.a-text .m-nav-main {
  margin: 0;
  list-style-type: none;
  padding: 0;
}
@media print, screen and (min-width: 64em) {
  .a-text .m-nav-main__item {
    display: block;
    margin-bottom: 10px;
  }
}
@media print, screen and (min-width: 64em) {
  .a-text .m-nav-main__item:hover .m-nav-main__sub {
    transform: none;
  }
}
.a-text .m-nav-main__item--expanded .m-nav-main__link:after {
  display: none;
}
.a-text .m-nav-main__link {
  padding: 0;
  color: #4b0e20;
}
.a-text .m-nav-main__sub {
  display: block;
}
@media print, screen and (min-width: 64em) {
  .a-text .m-nav-main__sub {
    transform: none;
    pointer-events: none;
    position: static;
    background: transparent;
    margin-top: 20px;
    min-width: auto;
    opacity: 1;
  }
}
@media print, screen and (min-width: 64em) {
  .a-text .m-nav-main__sub-item {
    opacity: 1;
    transform: none;
    margin-bottom: 10px;
  }
}
@media print, screen and (min-width: 64em) {
  .a-text .m-nav-main__sub-link {
    padding: 0;
  }
}
@media screen and (min-width: 80em) {
  .a-text .m-nav-main__sub-link:hover {
    background: transparent;
    text-decoration: underline;
  }
}

@media print, screen and (min-width: 64em) {
  .m-nav-social {
    display: inline-block;
  }
}
.m-nav-social__item {
  display: inline-block;
  margin-right: 10px;
}
@media print, screen and (min-width: 64em) {
  .m-nav-social__item {
    margin-bottom: 10px;
  }
}
.m-nav-social__item:last-child {
  margin-right: 0;
}
.m-nav-social__item span {
  display: none;
}
@media print, screen and (min-width: 64em) {
  .m-nav-social__item span {
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
  }
}
@media print, screen and (min-width: 64em) {
  .m-nav-social__link {
    display: block;
    color: #fff;
    text-decoration: none;
  }
}
@media screen and (min-width: 80em) {
  .m-nav-social__link:hover .m-nav-social__logo {
    fill: #e9660a;
  }
}
.m-nav-social__logo {
  display: inline-block;
  vertical-align: middle;
  background: #fff;
  width: 30px;
  height: 30px;
  fill: #4b0e20;
  padding: 4px;
  box-sizing: border-box;
}
@media screen and (min-width: 80em) {
  .m-nav-social__logo {
    transition: fill 0.15s;
  }
}

.m-contact .m-nav-social__logo {
  width: 40px;
  height: 40px;
}
.m-contact .m-nav-social span {
  display: none;
}

.m-nav-footer__item {
  margin-bottom: 10px;
}
.m-nav-footer__item:last-child {
  margin-bottom: 0;
}
.m-nav-footer__link {
  color: #a11e45;
}
@media screen and (min-width: 80em) {
  .m-nav-footer__link {
    transition: color 0.15s;
  }
}
@media screen and (min-width: 80em) {
  .m-nav-footer__link:hover {
    color: #4b0e20;
  }
}

.m-nav-legal__item {
  margin-bottom: 10px;
}
@media print, screen and (min-width: 64em) {
  .m-nav-legal__item {
    display: inline-block;
    margin-bottom: 0;
  }
}
@media print, screen and (min-width: 64em) {
  .m-nav-legal__item:after {
    content: "-";
    display: inline-block;
    margin: 0 10px;
    color: rgba(255, 255, 255, 0.5);
  }
}
.m-nav-legal__item:last-child {
  margin-bottom: 0;
}
@media print, screen and (min-width: 64em) {
  .m-nav-legal__item:last-child:after {
    display: none;
  }
}
.m-nav-legal__link {
  font-size: 12px;
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.5);
}

.m-nav-member {
  order: 2;
  margin-top: 40px;
  padding-left: 20px;
  padding-right: 40px;
}
@media print, screen and (min-width: 64em) {
  .m-nav-member {
    order: initial;
    padding: 0;
    margin-top: 0;
    margin-bottom: 20px;
    transition: opacity 0.5s;
  }
}
.m-nav-member__item {
  margin-bottom: 10px;
}
@media print, screen and (min-width: 64em) {
  .m-nav-member__item {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 0;
  }
}
.m-nav-member__item:first-child .m-nav-member__link {
  color: #e9b344;
}
@media screen and (min-width: 80em) {
  .m-nav-member__item:first-child .m-nav-member__link:hover {
    background-color: #e9b344;
    color: #fff;
  }
}
@media screen and (min-width: 80em) {
  .m-nav-member__item:first-child .m-nav-member__link:hover:before {
    content: url(../img/arrow-white.svg);
  }
}
.m-nav-member__item:first-child .m-nav-member__link:before {
  content: url(../img/arrow-tertiary.svg);
}
.m-nav-member__item:last-child {
  margin-bottom: 0;
}
@media print, screen and (min-width: 64em) {
  .m-nav-member__item:last-child {
    margin-right: 0;
  }
}
.m-nav-member__item:last-child .m-nav-member__link {
  background: #e9b344;
}
@media print, screen and (min-width: 64em) {
  .m-nav-member__item:last-child .m-nav-member__link {
    padding-left: 20px;
  }
}
@media screen and (min-width: 80em) {
  .m-nav-member__item:last-child .m-nav-member__link:hover {
    background: #eec672;
    border-color: #eec672;
  }
}
@media print, screen and (min-width: 64em) {
  .m-nav-member__item:last-child .m-nav-member__link:before {
    display: none;
  }
}
.m-nav-member__link {
  display: block;
  height: 40px;
  line-height: 36px;
  padding-right: 20px;
  padding-left: 60px;
  border: 1px solid #e9b344;
  box-sizing: border-box;
  color: #fff;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
}
@media screen and (min-width: 80em) {
  .m-nav-member__link {
    transition: background-color 0.15s, color 0.15s;
  }
}
.m-nav-member__link:before {
  content: url(../img/arrow-white.svg);
  position: absolute;
  top: 50%;
  left: 20px;
  width: 20px;
  transform: translateY(-50%);
  transition: opacity 0.15s;
}

@media print, screen and (min-width: 64em) {
  .o-body--scrolled .m-nav-member {
    opacity: 0;
  }
}

.m-form label {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.3em;
}
.m-form .form-item {
  margin-bottom: 20px;
}
.m-form .form-item:last-child {
  margin-bottom: 0;
}
.m-form .form-item.form-item-rgpd label {
  font-size: 11px;
  font-size: 0.6875rem;
  font-weight: 400;
  line-height: 1em;
}
.m-form input[type=text], .m-form input[type=password], .m-form input[type=email], .m-form select, .m-form textarea {
  display: block;
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  margin-top: 5px;
  padding: 10px;
  box-sizing: border-box;
  border: none;
  border: 1px solid #e3e3e3;
  background: transparent;
  color: #4b0e20;
  outline: none;
}
@media print, screen and (min-width: 64em) {
  .m-form input[type=text], .m-form input[type=password], .m-form input[type=email], .m-form select, .m-form textarea {
    padding: 12px 15px;
  }
}
.m-form input[type=checkbox], .m-form input[type=radio] {
  appearance: none;
  width: 16px;
  height: 16px;
  border: solid 1px #8c8c8c;
  background: #f8f8f8;
  vertical-align: middle;
  margin: 0;
  margin-top: -2px;
  margin-right: 3px;
  color: #fff;
  cursor: pointer;
  outline: none;
  position: relative;
  border-radius: 3px;
}
.m-form input[type=checkbox]:checked, .m-form input[type=radio]:checked {
  background: #547539;
  border-color: #547539;
}
.m-form input[type=checkbox]:checked:before, .m-form input[type=radio]:checked:before {
  display: block;
}
.m-form input[type=checkbox]:before, .m-form input[type=radio]:before {
  content: "✔";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  font-size: 0.75rem;
  display: none;
}
.m-form input[type=radio] {
  border-radius: 50%;
}
.m-form input[type=submit] {
  background: #547539;
  border: none;
  font-family: "Open Sans", sans-serif;
  outline: none;
  margin: 0;
  appearance: none;
  height: 44px;
  line-height: 42px;
  padding: 0 40px;
  color: #fff;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
  width: 100%;
}
.m-form .form-item--error-message {
  color: #de3b2f;
  font-size: 14px;
  font-size: 0.875rem;
  margin-top: 10px;
}
.m-form .form-item--error-message a {
  color: #4b0e20;
}
.m-form .description, .m-form .field-rgpd-text {
  font-size: 12px;
  font-size: 0.75rem;
  color: #a11e45;
  margin-top: 10px;
  line-height: 1.5em;
}
.m-form .description a, .m-form .field-rgpd-text a {
  color: #4b0e20;
}
.m-form #edit-processed-text, .m-form #edit-processed-text--2 {
  font-size: 11px;
  font-size: 0.6875rem;
  font-weight: 400;
  line-height: 1.3em;
  margin-bottom: 10px;
}
.m-form #edit-processed-text a, .m-form #edit-processed-text--2 a {
  color: #4b0e20;
}

.m-map {
  height: 430px;
  max-width: 100%;
  max-height: 80vh;
}
@media screen and (min-width: 80em) {
  .m-map {
    width: 1080px;
    margin: auto;
  }
}

.m-testimony {
  position: relative;
  text-align: center;
}
.m-testimony__citation {
  font-size: 25px;
  font-size: 1.5625rem;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  color: #4b0e20;
}
@media print, screen and (min-width: 64em) {
  .m-testimony__citation {
    font-size: 30px;
    font-size: 1.875rem;
  }
}
.m-testimony__citation:before, .m-testimony__citation:after {
  height: 40px;
  width: 40px;
  color: #4b0e20;
  font-size: 36px;
  font-size: 2.25rem;
}
.m-testimony__citation:before {
  content: "«";
  left: 0;
}
.m-testimony__citation:after {
  content: "»";
  right: 0;
}
.m-testimony__author {
  display: inline-block;
  text-align: left;
}
.m-testimony__author-photo {
  width: 50px;
  margin-right: 20px;
}
.m-testimony__author-photo img {
  border-radius: 100%;
}
.m-testimony__author div {
  vertical-align: middle;
  display: inline-block;
  max-width: 200px;
}
.m-testimony__author-name {
  display: block;
  font-size: 17px;
  font-size: 1.0625rem;
  color: #4b0e20;
  font-weight: 600;
  margin-bottom: 5px;
}
.m-testimony__author-function {
  display: block;
  font-size: 11px;
  font-size: 0.6875rem;
  text-align: left;
  color: #8c8c8c;
}
.m-testimony__author-linkedin {
  display: block;
  font-size: 11px;
  font-size: 0.6875rem;
  text-align: left;
  color: #86b7d0;
}

.m-view-adherents_list {
  text-align: center;
}
.m-view-adherents_list__filters {
  display: none;
}
.m-view-adherents_list__tags {
  margin-bottom: 60px;
}
@media print, screen and (min-width: 48em) {
  .m-view-adherents_list__tags {
    margin-top: 0;
  }
}
@media screen and (min-width: 80em) {
  .m-view-adherents_list__tags {
    width: 1080px;
    margin: auto;
    margin-bottom: 60px;
  }
}
.m-view-adherents_list__tags .views-exposed-form {
  display: flex;
  flex-flow: row wrap;
}
.m-view-adherents_list__tags .form-item {
  display: none;
}
.m-view-adherents_list__tags form > input {
  width: 50%;
  font-weight: 400;
  font-size: 14px;
  font-size: 0.875rem;
  background: #f8f8f8;
  padding: 15px;
  border: none;
  box-sizing: border-box;
}
@media print, screen and (min-width: 48em) {
  .m-view-adherents_list__tags form > input {
    margin-bottom: 0;
    width: 33.33%;
    padding: 0 20px;
    font-size: 16px;
    font-size: 1rem;
  }
}
.m-view-adherents_list__tags form > input[list=departments] {
  border-right: 1px solid #e3e3e3;
}
.m-view-adherents_list__tags form > input::placeholder {
  color: #8c8c8c;
}
.m-view-adherents_list__tags .webform-button--submit-container {
  display: block;
  background: #e9660a;
  color: #fff;
  text-decoration: none;
  text-align: center;
  position: relative;
  width: 100%;
}
@media print, screen and (min-width: 48em) {
  .m-view-adherents_list__tags .webform-button--submit-container {
    width: 33.33%;
  }
}
.m-view-adherents_list__tags .webform-button--submit-container:before {
  content: url(../img/arrow-white.svg);
  position: absolute;
  top: 50%;
  left: 20px;
  width: 20px;
  transform: translateY(-50%);
  transition: opacity 0.15s;
}
.m-view-adherents_list__tags .webform-button--submit-container:after {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid #fff;
  border-left-color: transparent;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.15s;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
.m-view-adherents_list__tags .webform-button--submit-container--loading {
  pointer-events: none;
}
.m-view-adherents_list__tags .webform-button--submit-container--loading:after {
  opacity: 1;
  animation-name: spin;
  transition: opacity 0.3s;
}
.m-view-adherents_list__tags .webform-button--submit-container input {
  display: block;
  width: 100%;
  background: transparent;
  border: none;
  color: #fff;
  font-size: 16px;
  font-size: 1rem;
  padding: 0 40px;
  height: 44px;
  line-height: 44px;
  cursor: pointer;
}
@media print, screen and (min-width: 48em) {
  .m-view-adherents_list__tags .webform-button--submit-container input {
    height: 47px;
    line-height: 47px;
  }
}
.m-view-adherents_list__rows {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: -40px;
}
@media print, screen and (min-width: 48em) {
  .m-view-adherents_list__rows {
    justify-content: space-evenly;
    margin-right: -10px;
    margin-left: -10px;
  }
}
@media screen and (min-width: 80em) {
  .m-view-adherents_list__rows {
    justify-content: normal;
    margin-bottom: -60px;
  }
}
.m-view-adherents_list__rows--loading {
  pointer-events: none;
  opacity: 0.5;
  transition: opacity 0.15s;
}
.m-view-adherents_list__row {
  flex: 0 0 auto;
  width: calc(50% - 1.25rem);
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  text-align: center;
  margin-bottom: 40px;
  position: relative;
}
@media print, screen and (min-width: 48em) {
  .m-view-adherents_list__row {
    flex: 0 0 auto;
    width: calc(33.3333333333% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
}
@media screen and (min-width: 80em) {
  .m-view-adherents_list__row {
    flex: 0 0 auto;
    width: calc(16.6666666667% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-bottom: 60px;
  }
}
@media screen and (min-width: 80em) {
  .m-view-adherents_list__row:nth-child(3n+1) {
    margin-left: calc(16.6666666667% + 1.25rem / 2);
  }
}
@media screen and (min-width: 80em) {
  .m-view-adherents_list__row:nth-child(3n+2), .m-view-adherents_list__row:nth-child(3n+3) {
    margin-left: calc(8.3333333333% + 1.25rem / 2);
  }
}
.m-view-adherents_list__visual {
  clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  margin-bottom: 20px;
  padding-bottom: 115%;
  position: relative;
  background: #f8f8f8;
}
.m-view-adherents_list__visual picture img {
  display: block;
  width: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 160px;
  height: 115px;
  max-width: 80%;
  mix-blend-mode: darken;
  object-fit: contain;
  image-rendering: -webkit-optimize-contrast;
}
.m-view-adherents_list__visual picture img .lazyloaded {
  transform: translate(-50%, -50%) scale(1);
}
.m-view-adherents_list__department {
  color: #86b7d0;
  font-size: 14px;
  font-size: 0.875rem;
  margin-bottom: 5px;
}
.m-view-adherents_list__more-container {
  text-align: center;
}
.m-view-adherents_list__more {
  display: inline-block;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
  color: #e9660a;
  margin-top: 40px;
}
@media print, screen and (min-width: 64em) {
  .m-view-adherents_list__more {
    margin-top: 60px;
  }
}
.m-view-adherents_list__more--loading {
  pointer-events: none;
  opacity: 0.5;
}
.m-view-adherents_list__more--inactive {
  display: none;
}
.m-view-adherents_list nav {
  display: none;
}
.m-view-adherents_list__info-window {
  display: flex;
  align-items: center;
  padding: 5px;
}
.m-view-adherents_list__info-visual-container {
  display: none;
}
@media print, screen and (min-width: 48em) {
  .m-view-adherents_list__info-visual-container {
    display: block;
    width: 100px;
  }
}
@media print, screen and (min-width: 48em) {
  .m-view-adherents_list__info-visual {
    clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
    padding-bottom: 115%;
    position: relative;
    background: #f8f8f8;
  }
}
@media print, screen and (min-width: 48em) {
  .m-view-adherents_list__info-visual img {
    display: block;
    width: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 65px;
    max-width: 80%;
    mix-blend-mode: darken;
    object-fit: contain;
    image-rendering: -webkit-optimize-contrast;
  }
}
@media print, screen and (min-width: 48em) {
  .m-view-adherents_list__info-visual img .lazyloaded {
    transform: translate(-50%, -50%) scale(1);
  }
}
.m-view-adherents_list__info-content {
  text-align: center;
  box-sizing: border-box;
  max-width: 260px;
}
@media print, screen and (min-width: 48em) {
  .m-view-adherents_list__info-content {
    text-align: left;
    padding-left: 20px;
  }
}

@keyframes spin {
  from {
    transform: translateY(-50%) rotate(0deg);
  }
  to {
    transform: translateY(-50%) rotate(360deg);
  }
}
@keyframes spin2 {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes slideIn {
  from {
    transform: translateY(60px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.m-view-news {
  text-align: center;
  position: relative;
}
.m-view-news:after {
  content: "";
  position: absolute;
  top: 0;
  right: -10px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
  width: 20px;
  height: 34px;
}
@media screen and (min-width: 22.5em) {
  .m-view-news:after {
    right: -20px;
  }
}
.m-view-news.swiper-container {
  overflow: visible;
}
.m-view-news_related__tags, .m-view-news_related__more {
  display: none;
}
.m-view-news__tags {
  margin: auto;
  margin-right: -10px;
  margin-bottom: 35px;
  margin-left: -10px;
  text-align: left;
  white-space: nowrap;
  overflow: auto;
  position: relative;
}
@media screen and (min-width: 22.5em) {
  .m-view-news__tags {
    margin-right: -20px;
    margin-left: -20px;
  }
}
@media print, screen and (min-width: 64em) {
  .m-view-news__tags {
    white-space: initial;
    overflow: visible;
    margin-right: 0;
    margin-left: 0;
  }
}
@media screen and (min-width: 80em) {
  .m-view-news__tags {
    max-width: 1080px;
    margin: auto;
    margin-bottom: 55px;
  }
}
.m-view-news__tags--loading {
  pointer-events: none;
  opacity: 0.3;
}
.m-view-news__tags form {
  display: none;
}
.m-view-news__tag {
  display: inline-block;
  color: #86b7d0;
  text-decoration: none;
  padding: 10px 0;
  padding-left: 25px;
  box-sizing: border-box;
  margin-right: 20px;
  position: relative;
}
.m-view-news__tag:nth-child(2) {
  margin-left: 10px;
}
@media screen and (min-width: 22.5em) {
  .m-view-news__tag:nth-child(2) {
    margin-left: 20px;
  }
}
@media print, screen and (min-width: 64em) {
  .m-view-news__tag:nth-child(2) {
    margin-left: 0;
  }
}
.m-view-news__tag--reset {
  display: none;
}
.m-view-news__tag:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
  border: 1px solid #86b7d0;
  box-sizing: border-box;
}
.m-view-news__tag:after {
  content: "";
  width: 16px;
  height: 15px;
  background-image: url(../img/leaf-secondary.svg);
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 8px;
  left: 2px;
  transform: rotate(80deg) scale(0);
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
}
@media print, screen and (min-width: 64em) {
  .m-view-news__tag:hover:after {
    opacity: 0.5;
    transform: rotate(80deg) scale(1);
  }
}
.m-view-news__tag--active:after {
  opacity: 1 !important;
  transform: rotate(80deg) scale(1);
}
.m-view-news__rows {
  margin-bottom: -40px;
}
@media print, screen and (min-width: 48em) {
  .m-view-news__rows {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin-right: -10px;
    margin-left: -10px;
  }
}
@media screen and (min-width: 80em) {
  .m-view-news__rows {
    justify-content: normal;
  }
}
.m-view-news__rows--loading {
  pointer-events: none;
  opacity: 0.5;
}
.m-view-news__more {
  display: inline-block;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
  color: #e9660a;
  margin-top: 40px;
}
@media print, screen and (min-width: 64em) {
  .m-view-news__more {
    margin-top: 60px;
  }
}
.m-view-news__more--loading {
  pointer-events: none;
  opacity: 0.5;
}
.m-view-news__more--inactive {
  display: none;
}
.m-view-news__row {
  text-align: center;
  margin-bottom: 40px;
  position: relative;
}
@media print, screen and (min-width: 48em) {
  .m-view-news__row {
    flex: 0 0 auto;
    width: calc(33.3333333333% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
}
@media screen and (min-width: 80em) {
  .m-view-news__row {
    flex: 0 0 auto;
    width: calc(16.6666666667% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
}
@media screen and (min-width: 80em) {
  .m-view-news__row:nth-child(3n+1) {
    margin-left: calc(16.6666666667% + 1.25rem / 2);
  }
}
@media print, screen and (min-width: 64em) {
  .m-view-news__row:nth-child(3n+2), .m-view-news__row:nth-child(3n+3) {
    --sal-delay: 0.15s;
  }
}
@media screen and (min-width: 80em) {
  .m-view-news__row:nth-child(3n+2), .m-view-news__row:nth-child(3n+3) {
    margin-left: calc(8.3333333333% + 1.25rem / 2);
  }
}
@media print, screen and (min-width: 64em) {
  .m-view-news__row:nth-child(3n+3) {
    --sal-delay: 0.3s;
  }
}
@media screen and (min-width: 80em) {
  .m-view-news__row .a-heading {
    transition: color 0.5s;
  }
}
.m-view-news__row picture {
  display: inline-block;
  clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  margin-bottom: 20px;
}
@media screen and (min-width: 80em) {
  .m-view-news__row picture {
    transition: clip-path 0.5s;
  }
}
.m-view-news__row img {
  display: block;
  width: 200px;
}
@media screen and (min-width: 80em) {
  .m-view-news__row:hover {
    opacity: 1 !important;
  }
}
@media screen and (min-width: 80em) {
  .m-view-news__row:hover .a-heading {
    color: #5c9344;
  }
}
@media screen and (min-width: 80em) {
  .m-view-news__row:hover picture {
    clip-path: polygon(5% 30%, 50% 7.5%, 95% 30%, 95% 70%, 50% 92.5%, 5% 70%);
  }
}
.m-view-news__categories {
  color: #86b7d0;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.3em;
  margin-bottom: 10px;
}
.m-view-news__categories span:after {
  content: ", ";
  display: inline;
}
.m-view-news__categories span:last-child:after {
  display: none;
}
.m-view-news__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.o-block--a-la-une .m-view-news__row {
  max-width: 200px;
}

.m-contact {
  display: flex;
  flex-flow: row wrap;
  margin-right: -10px;
  margin-left: -10px;
  background: #f8f8f8;
  padding: 60px 0;
}
@media screen and (min-width: 22.5em) {
  .m-contact {
    margin-right: -20px;
    margin-left: -20px;
    padding: 60px 10px;
  }
}
@media print, screen and (min-width: 64em) {
  .m-contact {
    padding: 80px 0;
    background: transparent;
    position: relative;
  }
}
@media print, screen and (min-width: 64em) {
  .m-contact:before {
    content: "";
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    left: 10px;
    background: #f8f8f8;
  }
}
@media screen and (min-width: 80em) {
  .m-contact:before {
    width: 1080px;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }
}
.m-contact__left {
  flex: 0 0 auto;
  width: calc(100% - 1.25rem);
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  order: 2;
}
@media print, screen and (min-width: 64em) {
  .m-contact__left {
    flex: 0 0 auto;
    width: calc(41.6666666667% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    order: initial;
    padding-left: 60px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
  }
}
@media screen and (min-width: 80em) {
  .m-contact__left {
    flex: 0 0 auto;
    width: calc(33.3333333333% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-left: calc(8.3333333333% + 1.25rem / 2);
    padding-left: 80px;
  }
}
.m-contact__right {
  flex: 0 0 auto;
  width: calc(100% - 1.25rem);
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  margin-bottom: 60px;
}
@media print, screen and (min-width: 64em) {
  .m-contact__right {
    flex: 0 0 auto;
    width: calc(50% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-left: calc(8.3333333333% + 1.25rem / 2);
    margin-bottom: 0;
    padding-right: 60px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
  }
}
@media screen and (min-width: 80em) {
  .m-contact__right {
    flex: 0 0 auto;
    width: calc(41.6666666667% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-left: calc(8.3333333333% + 1.25rem / 2);
    padding-right: 80px;
  }
}

.m-teasings {
  margin-right: -10px;
  margin-left: -10px;
}
@media screen and (min-width: 22.5em) {
  .m-teasings {
    margin-right: -20px;
    margin-left: -20px;
  }
}
@media print, screen and (min-width: 48em) {
  .m-teasings--double {
    display: flex;
    flex-flow: row wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
}
.m-teasings--double .m-teasings__teasing {
  display: flex;
  align-items: flex-end;
  position: relative;
}
@media print, screen and (min-width: 48em) {
  .m-teasings--double .m-teasings__teasing {
    flex: 0 0 auto;
    width: calc(50% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    padding: 0;
    box-sizing: border-box;
  }
}
@media screen and (min-width: 80em) {
  .m-teasings--double .m-teasings__teasing {
    overflow: hidden;
  }
}
@media screen and (min-width: 85.375em) {
  .m-teasings--double .m-teasings__teasing {
    min-height: 480px;
  }
}
@media print, screen and (min-width: 64em) {
  .m-teasings--double .m-teasings__teasing:last-child {
    --sal-delay: 0.15s;
  }
}
.m-teasings--double .m-teasings__teasing:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.25) 82%);
}
@media screen and (min-width: 80em) {
  .m-teasings--double .m-teasings__teasing:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateY(-50%) scale(0);
    z-index: 1;
    opacity: 0;
    background: #86b7d0;
    padding-bottom: 115%;
    clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
    transition: transform 0.5s, opacity 0.5s;
  }
}
@media screen and (min-width: 80em) {
  .m-teasings--double .m-teasings__teasing:hover:after {
    transform: translateY(-50%) scale(1.2);
    opacity: 1;
  }
}
@media screen and (min-width: 80em) {
  .m-teasings--double .m-teasings__teasing:hover .m-teasings__leaves {
    opacity: 1;
    transition: opacity 0s;
  }
}
@media screen and (min-width: 80em) {
  .m-teasings--double .m-teasings__teasing:hover .a-btn {
    background: #f68230;
  }
}
.m-teasings--double .m-teasings__teasing img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.m-teasings--double .m-teasings__content {
  background: transparent;
  position: relative;
  z-index: 2;
  padding: 60px 20px;
}
@media print, screen and (min-width: 48em) {
  .m-teasings--double .m-teasings__content {
    padding: 60px;
    z-index: 3;
  }
}
@media screen and (min-width: 85.375em) {
  .m-teasings--double .m-teasings__content {
    padding: 60px 40px 60px 120px;
  }
}
.m-teasings--double .m-teasings__content .a-heading {
  color: #fff;
}
.m-teasings--double .m-teasings__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
}
.m-teasings--double .m-teasings__leaves {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.3s;
}
@media print, screen and (min-width: 48em) {
  .m-teasings__teasing {
    position: relative;
    padding: 40px;
    padding-left: 33%;
    box-sizing: border-box;
  }
}
@media print, screen and (min-width: 64em) {
  .m-teasings__teasing {
    padding-left: 50%;
  }
}
@media screen and (min-width: 85.375em) {
  .m-teasings__teasing {
    padding: 40px 60px;
    padding-left: 50%;
  }
}
@media print, screen and (min-width: 48em) {
  .m-teasings__teasing img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@media screen and (min-width: 85.375em) {
  .m-teasings__teasing img {
    max-width: 1300px;
  }
}
.m-teasings__content {
  background: #f8f8f8;
  padding: 40px 20px;
  box-sizing: border-box;
}
@media print, screen and (min-width: 48em) {
  .m-teasings__content {
    position: relative;
    z-index: 1;
  }
}
@media screen and (min-width: 85.375em) {
  .m-teasings__content {
    padding: 60px;
  }
}

.m-list {
  counter-reset: item;
}
@media print, screen and (min-width: 64em) {
  .m-list {
    display: flex;
    flex-flow: row wrap;
  }
}
@media print, screen and (min-width: 64em) {
  .m-list__titles {
    flex: 0 0 auto;
    width: calc(33.3333333333% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-bottom: 40px;
    padding-left: 40px;
    box-sizing: border-box;
  }
}
@media screen and (min-width: 80em) {
  .m-list__titles {
    margin-left: calc(8.3333333333% + 1.25rem / 2);
  }
}
@media print, screen and (min-width: 64em) {
  .m-list__title {
    display: block;
    box-sizing: border-box;
    position: relative;
    transition: padding 0.3s, color 0.3s;
    padding: 20px 0;
    font-size: 18px;
    font-size: 1.125rem;
    border-bottom: 1px solid #e3e3e3;
    cursor: pointer;
  }
}
@media screen and (min-width: 85.375em) {
  .m-list__title {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 80em) {
  .m-list__title:hover {
    color: #e9660a;
  }
}
@media print, screen and (min-width: 64em) {
  .m-list__title:last-child {
    border-bottom: none;
  }
}
@media print, screen and (min-width: 64em) {
  .m-list__title:before {
    content: "";
    width: 24px;
    height: 32px;
    background-image: url(../img/leaf.svg);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    left: -50px;
    transform: translateY(-50%) rotate(80deg) scale(0);
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
  }
}
@media screen and (min-width: 85.375em) {
  .m-list__title:before {
    width: 28px;
    height: 38px;
    left: -60px;
  }
}
@media print, screen and (min-width: 64em) {
  .m-list__title:after {
    counter-increment: item;
    content: counter(item);
    position: absolute;
    top: 50%;
    left: -41px;
    transform: translateY(-50%);
    font-size: 12px;
    font-size: 0.75rem;
    color: #fff;
    opacity: 0;
    transition: opacity 0.3s;
  }
}
@media screen and (min-width: 85.375em) {
  .m-list__title:after {
    font-size: 16px;
    font-size: 1rem;
    margin-top: -1px;
    left: -50px;
  }
}
@media print, screen and (min-width: 64em) {
  .m-list__title--active {
    color: #e9660a;
  }
}
@media print, screen and (min-width: 64em) {
  .m-list__title--active:before {
    opacity: 1;
    transform: translateY(-50%) rotate(80deg) scale(1);
  }
}
@media print, screen and (min-width: 64em) {
  .m-list__title--active:after {
    opacity: 1;
  }
}
.m-list .swiper-container {
  overflow: visible;
}
@media print, screen and (min-width: 64em) {
  .m-list .swiper-container {
    overflow: hidden;
  }
}
.m-list .swiper-wrapper {
  display: block;
}
@media print, screen and (min-width: 64em) {
  .m-list .swiper-wrapper {
    display: flex;
  }
}
@media print, screen and (min-width: 64em) {
  .m-list__items {
    flex: 0 0 auto;
    width: calc(50% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-left: calc(8.3333333333% + 1.25rem / 2);
    --sal-delay: 0.15s;
  }
}
@media screen and (min-width: 80em) {
  .m-list__items {
    flex: 0 0 auto;
    width: calc(41.6666666667% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-left: calc(8.3333333333% + 1.25rem / 2);
  }
}
.m-list__item {
  border-top: 1px solid #4b0e20;
}
@media print, screen and (min-width: 64em) {
  .m-list__item {
    border-top: none;
  }
}
.m-list__item:last-child {
  border-bottom: 1px solid #4b0e20;
}
@media print, screen and (min-width: 64em) {
  .m-list__item:last-child {
    border-bottom: none;
  }
}
.m-list__question {
  display: block;
  box-sizing: border-box;
  position: relative;
  transition: padding 0.3s, color 0.3s;
  padding: 15px 0;
  cursor: pointer;
  font-size: 16px;
  font-size: 1rem;
  transition: padding 0.3s;
}
@media print, screen and (min-width: 64em) {
  .m-list__question {
    display: none;
  }
}
.m-list__question:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  height: 2px;
  width: 8px;
  background: #4b0e20;
}
@media print, screen and (min-width: 64em) {
  .m-list__question:before {
    display: none;
  }
}
.m-list__question:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 3px;
  transform: translateY(-50%);
  height: 8px;
  width: 2px;
  background: #4b0e20;
  transition: opacity 0.15s;
}
@media print, screen and (min-width: 64em) {
  .m-list__question:after {
    display: none;
  }
}
.m-list__question.accordino__question--opened {
  color: #e9660a;
}
.m-list__question.accordino__question--opened:after {
  opacity: 0;
}
.m-list__answer {
  display: none;
  overflow: hidden;
  background: #f8f8f8;
  padding: 0 20px;
}
@media print, screen and (min-width: 64em) {
  .m-list__answer {
    display: block;
    background: transparent;
    padding: 0;
  }
}
.m-list__answer:before {
  content: "";
  display: block;
  height: 20px;
}
@media print, screen and (min-width: 64em) {
  .m-list__answer:before {
    display: none;
  }
}
.m-list__answer:after {
  content: "";
  display: block;
  height: 20px;
}
@media print, screen and (min-width: 64em) {
  .m-list__answer:after {
    display: none;
  }
}

.m-stats {
  margin-right: -10px;
  margin-left: -10px;
}
@media screen and (min-width: 22.5em) {
  .m-stats {
    margin-right: -20px;
    margin-left: -20px;
  }
}
@media print, screen and (min-width: 64em) {
  .m-stats {
    display: flex;
    flex-flow: row wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
}
.m-stats__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}
@media print, screen and (min-width: 64em) {
  .m-stats__item {
    flex: 0 0 auto;
    width: calc(50% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    height: 480px;
  }
}
@media screen and (min-width: 80em) {
  .m-stats__item:hover .m-stats__leaves {
    opacity: 1;
    transition: opacity 0s;
  }
}
.m-stats__item:nth-child(1) {
  background: #86b7d0;
  color: #fff;
}
.m-stats__item:nth-child(1) .a-leaves__leaf {
  fill: #fff;
}
.m-stats__item:nth-child(2) {
  background: #f8f8f8;
  color: #547539;
}
@media print, screen and (min-width: 64em) {
  .m-stats__item:nth-child(2) {
    --sal-delay: 0.15s;
  }
}
.m-stats__item:nth-child(2) .m-stats__number:after {
  background: #547539;
}
.m-stats__item:nth-child(2) .a-leaves__leaf {
  fill: #547539;
}
.m-stats__number {
  font-family: "Quattrocento Sans", sans-serif;
  font-weight: 700;
  font-size: 90px;
  font-size: 5.625rem;
  line-height: 0.8em;
  letter-spacing: -0.03em;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;
  text-align: center;
}
@media print, screen and (min-width: 64em) {
  .m-stats__number {
    font-size: 120px;
    font-size: 7.5rem;
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
}
.m-stats__number:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 1px;
  background: #fff;
}
@media print, screen and (min-width: 64em) {
  .m-stats__number:after {
    width: 300px;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }
}
.m-stats__text {
  font-size: 16px;
  font-size: 1rem;
}
@media print, screen and (min-width: 64em) {
  .m-stats__text {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 80em) {
  .m-stats__leaves {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s;
  }
}

.m-view-taxonomy_list_job ul {
  margin-right: -10px;
  margin-left: -10px;
  text-align: left;
  white-space: nowrap;
  overflow: auto;
}
@media screen and (min-width: 22.5em) {
  .m-view-taxonomy_list_job ul {
    margin-right: -20px;
    margin-left: -20px;
  }
}
@media print, screen and (min-width: 64em) {
  .m-view-taxonomy_list_job ul {
    white-space: initial;
    overflow: visible;
    margin-right: 0;
    margin-left: 0;
  }
}
@media screen and (min-width: 80em) {
  .m-view-taxonomy_list_job ul {
    max-width: 1080px;
    margin: auto;
  }
}
.m-view-taxonomy_list_job li {
  display: inline-block;
  margin-right: 20px;
}
.m-view-taxonomy_list_job li:first-child {
  margin-left: 20px;
}
@media print, screen and (min-width: 64em) {
  .m-view-taxonomy_list_job li:first-child {
    margin-left: 0;
  }
}
.m-view-taxonomy_list_job a {
  display: block;
  padding: 10px 15px;
  color: #86b7d0;
  border: 1px solid #86b7d0;
  box-sizing: border-box;
  text-decoration: none;
}
@media screen and (min-width: 80em) {
  .m-view-taxonomy_list_job a {
    transition: border-color 0.15s, background-color 0.15s, color 0.15s;
  }
}
@media screen and (min-width: 80em) {
  .m-view-taxonomy_list_job a:hover {
    border-color: #e9660a;
    background: #e9660a;
    color: #fff;
  }
}

.m-resource {
  position: relative;
  margin-right: -10px;
  margin-left: -10px;
  padding-top: 60px;
  padding-right: 10px;
  padding-bottom: 60px;
  padding-left: 10px;
}
@media screen and (min-width: 22.5em) {
  .m-resource {
    margin-right: -20px;
    margin-left: -20px;
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media print, screen and (min-width: 64em) {
  .m-resource {
    margin-right: 0;
    margin-left: 0;
    padding: 120px 80px 80px;
  }
}
.m-resource:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.25) 82%);
}
@media screen and (min-width: 80em) {
  .m-resource:before {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 82%);
    opacity: 0.5;
    transition: opacity 0.3s;
  }
}
@media screen and (min-width: 80em) {
  .m-resource:hover:before {
    opacity: 1;
  }
}
.m-resource:hover .a-heading {
  color: #5c9344;
}
.m-resource__content {
  position: relative;
  z-index: 1;
}
@media print, screen and (min-width: 64em) {
  .m-resource__content {
    width: 60%;
  }
}
.m-resource__content .a-heading {
  transition: color 0.3s;
}
.m-resource__content .a-link {
  margin-top: 20px;
  color: #fff;
}
.m-resource img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(25%);
}
@media screen and (min-width: 85.375em) {
  .m-resource img {
    max-width: 1300px;
  }
}
.m-resource__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

@media print, screen and (min-width: 48em) {
  .m-missions {
    display: flex;
    flex-flow: row wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
}
.m-missions.swiper-container {
  overflow: visible;
}
.m-missions__mission {
  max-width: 200px;
}
@media print, screen and (min-width: 48em) {
  .m-missions__mission {
    flex: 0 0 auto;
    width: calc(33.3333333333% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    max-width: none;
  }
}
@media print, screen and (min-width: 64em) {
  .m-missions__mission {
    flex: 0 0 auto;
    width: calc(25% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    max-width: none;
  }
}
@media screen and (min-width: 85.375em) {
  .m-missions__mission {
    flex: 0 0 auto;
    width: calc(16.6666666667% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-left: calc(8.3333333333% + 1.25rem / 2);
  }
}
@media print, screen and (min-width: 64em) {
  .m-missions__mission:nth-child(3n+1) {
    margin-left: calc(8.3333333333% + 1.25rem / 2);
  }
}
@media screen and (min-width: 85.375em) {
  .m-missions__mission:nth-child(3n+1) {
    margin-left: calc(16.6666666667% + 1.25rem / 2);
  }
}
@media print, screen and (min-width: 64em) {
  .m-missions__mission:nth-child(3n+2) {
    flex: 0 0 auto;
    width: calc(33.3333333333% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    --sal-delay: 0.15s;
  }
}
@media screen and (min-width: 85.375em) {
  .m-missions__mission:nth-child(3n+2) {
    flex: 0 0 auto;
    width: calc(16.6666666667% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-left: calc(8.3333333333% + 1.25rem / 2);
  }
}
@media print, screen and (min-width: 64em) {
  .m-missions__mission:nth-child(3n+3) {
    --sal-delay: 0.3s;
  }
}
.m-missions__mission img {
  display: block;
  width: 140px;
  margin: auto;
  margin-bottom: 20px;
}
.m-missions__content {
  text-align: center;
}
@media print, screen and (min-width: 48em) {
  .m-missions__content {
    max-width: 200px;
    margin: auto;
  }
}

.m-chronology__item {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 40px;
  position: relative;
}
@media print, screen and (min-width: 48em) {
  .m-chronology__item {
    margin-bottom: 60px;
  }
}
.m-chronology__item:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: -40px;
  left: 35.5px;
  width: 1px;
  background: #8c8c8c;
}
@media print, screen and (min-width: 48em) {
  .m-chronology__item:before {
    bottom: -60px;
    left: 49.5px;
  }
}
.m-chronology__item:last-child {
  margin-bottom: 0;
}
.m-chronology__item:last-child:before {
  display: none;
}
.m-chronology__number {
  width: 72px;
  height: 72px;
  position: relative;
}
@media print, screen and (min-width: 48em) {
  .m-chronology__number {
    width: 100px;
    height: 100px;
  }
}
.m-chronology__number img {
  display: block;
}
.m-chronology__title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Quattrocento Sans", sans-serif;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
  color: #fff;
}
@media print, screen and (min-width: 48em) {
  .m-chronology__title {
    font-size: 26px;
    font-size: 1.625rem;
  }
}
.m-chronology__content {
  width: calc(100% - 72px);
  padding-left: 30px;
  box-sizing: border-box;
}
@media print, screen and (min-width: 48em) {
  .m-chronology__content {
    width: calc(100% - 100px);
    padding-top: 15px;
    padding-left: 60px;
  }
}
@media print, screen and (min-width: 64em) {
  .m-chronology__content {
    --sal-delay: 0.15s;
  }
}

@media print, screen and (min-width: 48em) {
  .m-team {
    margin-bottom: -40px;
  }
}
@media screen and (min-width: 80em) {
  .m-team {
    margin-bottom: -60px;
  }
}
.m-team.swiper-container {
  overflow: visible;
}
.m-team.swiper-container .m-view-news__row.swiper-slide-active .m-view-news__content {
  opacity: 1;
}
.m-team.swiper-container .m-view-news__content {
  opacity: 0;
  transition: opacity 0.5s;
}
@media print, screen and (min-width: 48em) {
  .m-team__rows {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin-right: -10px;
    margin-left: -10px;
  }
}
@media screen and (min-width: 80em) {
  .m-team__rows {
    justify-content: normal;
  }
}
.m-team__member {
  text-align: center;
  max-width: 200px;
}
@media print, screen and (min-width: 48em) {
  .m-team__member {
    flex: 0 0 auto;
    width: calc(33.3333333333% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 80em) {
  .m-team__member {
    flex: 0 0 auto;
    width: calc(16.6666666667% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-bottom: 60px;
  }
}
@media screen and (min-width: 80em) {
  .m-team__member:nth-child(3n+1) {
    margin-left: calc(16.6666666667% + 1.25rem / 2);
  }
}
@media print, screen and (min-width: 64em) {
  .m-team__member:nth-child(3n+2), .m-team__member:nth-child(3n+3) {
    --sal-delay: 0.15s;
  }
}
@media screen and (min-width: 80em) {
  .m-team__member:nth-child(3n+2), .m-team__member:nth-child(3n+3) {
    margin-left: calc(8.3333333333% + 1.25rem / 2);
  }
}
@media print, screen and (min-width: 64em) {
  .m-team__member:nth-child(3n+3) {
    --sal-delay: 0.3s;
  }
}
.m-team__member img {
  display: block;
  clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  margin-bottom: 20px;
}
.m-team__member .a-text {
  margin-top: 10px;
}
.m-team__company {
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.3em;
  color: #86b7d0;
  margin-bottom: 10px;
}

.m-share {
  text-align: right;
}
.m-share__links-twitter {
  display: inline-block;
  width: 48px;
  height: 48px;
  padding: 7px;
  box-sizing: border-box;
  border-radius: 50%;
  background: #40bee2;
  margin-right: 20px;
}
.m-share__links-twitter svg {
  width: 100%;
  fill: #fff;
}
.m-share__links-linkedin {
  display: inline-block;
  width: 48px;
  height: 48px;
  padding: 7px;
  box-sizing: border-box;
  border-radius: 50%;
  background: #3b588b;
}
.m-share__links-linkedin svg {
  width: 100%;
  fill: #fff;
}

.m-cookie-bar {
  padding: 20px;
  max-width: 500px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  text-align: center;
  background: #fff;
  position: fixed;
  right: 20px;
  bottom: 20px;
  left: 20px;
  z-index: 2;
}
@media print, screen and (min-width: 48em) {
  .m-cookie-bar {
    right: auto;
    bottom: 10px;
    left: 10px;
  }
}
.m-cookie-bar .a-text .cookizi-bar__preferences, .m-cookie-bar .a-text .cookizi-bar__politics {
  font-weight: 400;
  padding-left: 0;
  color: #4b0e20;
  margin-top: 0;
}
.m-cookie-bar .a-text .cookizi-bar__preferences:before, .m-cookie-bar .a-text .cookizi-bar__politics:before {
  display: none;
}
.m-cookie-bar__buttons {
  margin-top: 20px;
}
.m-cookie-bar__buttons .a-text {
  display: block;
  margin-bottom: 10px;
  text-decoration: none;
  cursor: pointer;
}
@media print, screen and (min-width: 48em) {
  .m-cookie-bar__buttons .a-text {
    display: inline-block;
    margin-right: 10px;
  }
}
.m-cookie-bar__buttons .a-text a {
  padding-left: 0;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  margin-top: 0;
}
.m-cookie-bar__buttons .a-text a:before {
  display: none;
}
@media print, screen and (min-width: 64em) {
  .m-cookie-bar__buttons .a-btn {
    margin-left: 10px;
    vertical-align: middle;
    cursor: pointer;
  }
}

.m-cookie-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  transform: translateX(-100%);
  opacity: 0;
  pointer-events: none;
  transition: transform 0s 0.5s, opacity 0s 0.5s;
}
.m-cookie-sidebar--shown {
  transform: translateX(0);
  opacity: 1;
  pointer-events: auto;
  transition: transform 0s, opacity 0s;
}
.m-cookie-sidebar--shown .m-cookie-sidebar__mask {
  opacity: 1;
}
.m-cookie-sidebar--shown .m-cookie-sidebar__container {
  transform: translateX(0%);
}
.m-cookie-sidebar__mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(75, 14, 32, 0.7);
  opacity: 0;
  transition: opacity 0.5s;
}
.m-cookie-sidebar__container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 80%;
  max-width: 450px;
  background: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
  overflow: auto;
  transform: translateX(-100%);
  transition: transform 0.5s;
}
.m-cookie-sidebar__switch {
  display: flex;
  flex-flow: row wrap;
  margin: 20px auto 40px;
}
.m-cookie-sidebar__switch--mandatory {
  opacity: 0.5;
  pointer-events: none;
}
.m-cookie-sidebar__option {
  flex: 0 0 auto;
  width: calc(33.3333333333% - 1.25rem);
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  font-weight: 600;
  text-align: center;
}
.m-cookie-sidebar__switcher-container {
  flex: 0 0 auto;
  width: calc(33.3333333333% - 1.25rem);
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  position: relative;
}
.m-cookie-sidebar__switcher {
  display: block;
  width: 38px;
  height: 20px;
  background: #547539;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  cursor: pointer;
}
.m-cookie-sidebar__switcher:after {
  content: "";
  position: absolute;
  left: 0;
  width: 16px;
  height: 16px;
  margin: 2px 0 0 2px;
  background: #fff;
  border-radius: 50%;
  box-sizing: border-box;
  transition: left 0.3s;
}
.m-cookie-sidebar__switcher[data-value="1"] {
  background: green;
}
.m-cookie-sidebar__switcher[data-value="1"]:after {
  left: 50%;
}

@media print, screen and (min-width: 64em) {
  .o-body {
    padding-top: 138px;
  }
}
.o-body__mask {
  content: "";
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background: rgba(255, 255, 255, 0.6);
  transform: translateY(-100%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s, transform 0s 0.5s;
}
@media print, screen and (min-width: 64em) {
  .o-body--home {
    padding-top: 158px;
  }
}
.o-body--menu-on {
  overflow: hidden;
}
.o-body--menu-on .o-body__mask {
  transform: translateY(0);
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.5s, transform 0s;
}

.o-header {
  height: 84px;
  box-sizing: border-box;
  border-bottom: 1px solid #e3e3e3;
}
@media print, screen and (min-width: 64em) {
  .o-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 4;
    background: #fff;
    height: 138px;
    border-bottom: none;
    transition: height 0.5s;
  }
}
.o-header__container {
  display: flex;
  flex-flow: row wrap;
  height: 100%;
  align-items: center;
}
@media print, screen and (min-width: 64em) {
  .o-header__container {
    position: relative;
  }
}
@media print, screen and (min-width: 64em) {
  .o-header__container:after {
    content: "";
    position: absolute;
    right: 10px;
    bottom: 0;
    left: 10px;
    height: 1px;
    background: #e3e3e3;
    transition: opacity 0.3s;
  }
}
.o-header__left {
  flex: 0 0 auto;
  width: calc(100% - 1.25rem);
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media print, screen and (min-width: 64em) {
  .o-header__left {
    flex: 0 0 auto;
    width: calc(25% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
}
@media screen and (min-width: 85.375em) {
  .o-header__left {
    flex: 0 0 auto;
    width: calc(25% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-left: calc(8.3333333333% + 1.25rem / 2);
  }
}
@media print, screen and (min-width: 64em) {
  .o-header__right {
    flex: 0 0 auto;
    width: calc(75% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
}
@media screen and (min-width: 85.375em) {
  .o-header__right {
    flex: 0 0 auto;
    width: calc(58.3333333333% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
}
.o-header__menu {
  position: fixed;
  top: 0;
  right: 20px;
  bottom: 0;
  left: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  background: #547539;
  padding-top: 84px;
  overflow: auto;
  transform: translateX(-100%);
  pointer-events: none;
  transition: transform 0.5s;
  transition-timing-function: cubic-bezier(0.77, 0.2, 0.05, 1);
}
@media screen and (min-width: 22.5em) {
  .o-header__menu {
    right: 40px;
  }
}
@media print, screen and (min-width: 64em) {
  .o-header__menu {
    position: static;
    background: transparent;
    pointer-events: auto;
    transform: none;
    text-align: right;
    padding-top: 15px;
    overflow: visible;
    transition: margin 0.5s;
  }
}
@media print, screen and (min-width: 64em) {
  .o-header__mobile {
    display: none;
  }
}
.o-header__links {
  display: none;
}
@media screen and (min-width: 22.5em) {
  .o-header__links {
    display: inline-block;
    vertical-align: middle;
    position: relative;
  }
}
@media screen and (min-width: 22.5em) {
  .o-header__links:before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    height: 1px;
    transform: translateY(-1px);
    background: #e9b344;
  }
}
@media screen and (min-width: 22.5em) {
  .o-header__link {
    display: block;
    font-size: 12px;
    font-size: 0.75rem;
    text-decoration: none;
    color: #e9b344;
    margin-bottom: 20px;
  }
}
.o-header__link:last-child {
  margin-bottom: 0;
}
.o-header__logo {
  width: 134px;
}
@media print, screen and (min-width: 64em) {
  .o-header__logo {
    width: 245px;
    transition: width 0.5s;
  }
}
.o-header__logo img {
  display: block;
  width: 100%;
}

.o-body--home .o-header {
  border-bottom: none;
}
@media print, screen and (min-width: 64em) {
  .o-body--home .o-header {
    height: 158px;
  }
}
@media print, screen and (min-width: 64em) {
  .o-body--home .o-header__container:after {
    opacity: 0;
  }
}
@media print, screen and (min-width: 64em) {
  .o-body--scrolled .o-header {
    height: 100px;
  }
}
@media print, screen and (min-width: 64em) {
  .o-body--scrolled .o-header__container:after {
    opacity: 1;
  }
}
@media print, screen and (min-width: 64em) {
  .o-body--scrolled .o-header__logo {
    width: 180px;
  }
}
@media print, screen and (min-width: 64em) {
  .o-body--scrolled .o-header__menu {
    margin-top: -75px;
  }
}
.o-body--menu-on .o-header__menu {
  transform: translateX(0%);
  pointer-events: auto;
}

.o-block {
  margin-top: 60px;
  margin-bottom: 60px;
}
@media print, screen and (min-width: 64em) {
  .o-block {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}
@media screen and (min-width: 80em) {
  .o-block {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}
@media screen and (min-width: 85.375em) {
  .o-block {
    margin-top: 120px;
    margin-bottom: 120px;
  }
}
.o-block--padded {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media print, screen and (min-width: 64em) {
  .o-block--padded {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.o-block--primary {
  background-color: #547539;
}
.o-block--secondary {
  background-color: #e9660a;
}
.o-block--grey {
  background-color: #f8f8f8;
}
.o-block--overflow-hidden {
  overflow: hidden;
}
.o-block--centered {
  text-align: center;
}
.o-block--button {
  position: relative;
  text-align: center;
}
.o-block--cta {
  text-align: center;
}
.o-block--text-image-cta .a-link--cta {
  text-decoration: none;
  font-weight: normal;
  border-top: 1px solid #e3e3e3;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 0;
  display: block;
}
@media screen and (min-width: 80em) {
  .o-block--text-image-cta .a-link--cta {
    position: relative;
    display: block;
    padding: 10px 0;
    border: none;
    width: 70%;
    transition: padding 0.3s;
  }
}
.o-block--text-image-cta .a-link--cta:last-of-type {
  border-bottom: 1px solid #e3e3e3;
}
@media screen and (min-width: 80em) {
  .o-block--text-image-cta .a-link--cta:last-of-type {
    border: none;
  }
}
.o-block--text-image-cta .a-link--cta:first-of-type {
  margin-top: 20px !important;
}
.o-block--text-image-cta .a-link--cta:before {
  content: none;
}
@media screen and (min-width: 80em) {
  .o-block--text-image-cta .a-link--cta:before {
    content: "";
    border: 1px solid #547539;
    left: 0;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    pointer-events: none;
    z-index: -1;
    border-radius: 25px;
    opacity: 0;
    transition: opacity 0.5s;
  }
}
@media screen and (min-width: 80em) {
  .o-block--text-image-cta .a-link--cta:hover {
    font-weight: bold;
    color: #547539;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (min-width: 80em) {
  .o-block--text-image-cta .a-link--cta:hover:before {
    opacity: 1;
  }
}
@media screen and (min-width: 80em) {
  .o-block--text-image-cta .a-link--cta:hover:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 15px;
    height: 7px;
    width: 7px;
    border-top: 2px solid #547539;
    border-right: 2px solid #547539;
    box-sizing: border-box;
    transform: translateY(-50%) rotate(45deg);
  }
}
@media print, screen and (min-width: 64em) {
  .o-block--text-image-left .o-block__left {
    order: 2;
  }
}
.o-block--a-la-une, .o-block--list-cards, .o-block--missions, .o-block--logos, .o-block--team, .o-block--jobs, .o-block--job-teaser {
  overflow: hidden;
}
.o-block--list-number {
  margin-top: 0px;
  margin-bottom: 0px;
}
@media print, screen and (min-width: 48em) {
  .o-block--list-number {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}
@media print, screen and (min-width: 64em) {
  .o-block--list-number {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}
@media screen and (min-width: 80em) {
  .o-block--list-number {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}
@media screen and (min-width: 85.375em) {
  .o-block--list-number {
    margin-top: 120px;
    margin-bottom: 120px;
  }
}
.o-block--list-cards {
  margin-top: -60px;
}
@media print, screen and (min-width: 48em) {
  .o-block--list-cards {
    margin-top: 60px;
  }
}
@media print, screen and (min-width: 64em) {
  .o-block--list-cards {
    margin-top: 80px;
  }
}
@media screen and (min-width: 80em) {
  .o-block--list-cards {
    margin-top: 100px;
  }
}
@media screen and (min-width: 85.375em) {
  .o-block--list-cards {
    margin-top: 120px;
  }
}
.o-block--contact {
  margin-bottom: 0;
}
@media print, screen and (min-width: 48em) {
  .o-block--contact {
    margin-bottom: 60px;
  }
}
@media print, screen and (min-width: 64em) {
  .o-block--contact {
    margin-bottom: 80px;
  }
}
@media screen and (min-width: 80em) {
  .o-block--contact {
    margin-bottom: 100px;
  }
}
@media screen and (min-width: 85.375em) {
  .o-block--contact {
    margin-bottom: 120px;
  }
}
.o-block--jobs {
  margin-top: -20px;
}
@media print, screen and (min-width: 48em) {
  .o-block--jobs {
    overflow: visible;
  }
}
@media print, screen and (min-width: 64em) {
  .o-block--jobs {
    margin-top: -20px;
  }
}
@media screen and (min-width: 80em) {
  .o-block--jobs {
    margin-top: -40px;
  }
}
@media screen and (min-width: 80em) {
  .o-block--resource {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}
.o-block--news {
  margin-top: 0;
}
.o-block--new {
  position: relative;
  min-height: 500px;
}
.o-block--companies-map {
  margin-top: 60px;
}
.o-block--active .o-block__leaves {
  opacity: 1;
  transition: opacity 0s;
}
.o-block__container {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}
.o-block__container--centered {
  align-items: center;
}
.o-block__content {
  flex: 0 0 auto;
  width: calc(100% - 1.25rem);
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  margin-bottom: 40px;
}
.o-block__content:last-child {
  margin-bottom: 0;
}
@media print, screen and (min-width: 64em) {
  .o-block__content {
    margin-bottom: 60px;
  }
}
@media screen and (min-width: 80em) {
  .o-block__content {
    flex: 0 0 auto;
    width: calc(83.3333333333% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-left: calc(8.3333333333% + 1.25rem / 2);
  }
}
@media print, screen and (min-width: 64em) {
  .o-block__content--slim {
    flex: 0 0 auto;
    width: calc(83.3333333333% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-left: calc(8.3333333333% + 1.25rem / 2);
  }
}
@media screen and (min-width: 85.375em) {
  .o-block__content--slim {
    flex: 0 0 auto;
    width: calc(66.6666666667% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-left: calc(16.6666666667% + 1.25rem / 2);
  }
}
@media print, screen and (min-width: 64em) {
  .o-block__content--slimer {
    flex: 0 0 auto;
    width: calc(66.6666666667% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-left: calc(16.6666666667% + 1.25rem / 2);
  }
}
@media screen and (min-width: 80em) {
  .o-block__content--slimer {
    flex: 0 0 auto;
    width: calc(50% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-left: calc(25% + 1.25rem / 2);
  }
}
@media screen and (min-width: 80em) {
  .o-block__content--full {
    flex: 0 0 auto;
    width: calc(100% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
}
@media print, screen and (min-width: 64em) {
  .o-block__content--top {
    flex: 0 0 auto;
    width: calc(75% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
}
@media screen and (min-width: 80em) {
  .o-block__content--top {
    flex: 0 0 auto;
    width: calc(58.3333333333% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-left: calc(8.3333333333% + 1.25rem / 2);
  }
}
.o-block__content--centered {
  text-align: center;
}
.o-block__left {
  flex: 0 0 auto;
  width: calc(100% - 1.25rem);
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  margin-bottom: 40px;
}
@media print, screen and (min-width: 64em) {
  .o-block__left {
    flex: 0 0 auto;
    width: calc(50% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 80em) {
  .o-block__left {
    flex: 0 0 auto;
    width: calc(41.6666666667% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-left: calc(8.3333333333% + 1.25rem / 2);
  }
}
@media screen and (min-width: 80em) {
  .o-block__left--reverted {
    flex: 0 0 auto;
    width: calc(41.6666666667% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-bottom: 0;
    order: 2;
  }
}
.o-block__left--1-3 {
  flex: 0 0 auto;
  width: calc(100% - 1.25rem);
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}
@media print, screen and (min-width: 48em) {
  .o-block__left--1-3 {
    flex: 0 0 auto;
    width: calc(33.3333333333% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-bottom: 0;
  }
}
@media print, screen and (min-width: 64em) {
  .o-block__left--1-3 {
    flex: 0 0 auto;
    width: calc(25% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
}
@media screen and (min-width: 80em) {
  .o-block__left--1-3 {
    flex: 0 0 auto;
    width: calc(16.6666666667% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-left: calc(16.6666666667% + 1.25rem / 2);
  }
}
.o-block__right {
  flex: 0 0 auto;
  width: calc(100% - 1.25rem);
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}
@media print, screen and (min-width: 64em) {
  .o-block__right {
    flex: 0 0 auto;
    width: calc(50% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    --sal-delay: 0.2s;
  }
}
@media screen and (min-width: 80em) {
  .o-block__right {
    flex: 0 0 auto;
    width: calc(41.6666666667% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
}
@media screen and (min-width: 80em) {
  .o-block__right--reverted {
    flex: 0 0 auto;
    width: calc(41.6666666667% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-left: calc(8.3333333333% + 1.25rem / 2);
    order: 1;
  }
}
.o-block__right--2-3 {
  flex: 0 0 auto;
  width: calc(100% - 1.25rem);
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}
@media print, screen and (min-width: 48em) {
  .o-block__right--2-3 {
    flex: 0 0 auto;
    width: calc(66.6666666667% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
}
@media print, screen and (min-width: 64em) {
  .o-block__right--2-3 {
    flex: 0 0 auto;
    width: calc(50% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    display: flex;
    align-items: center;
  }
}
@media screen and (min-width: 80em) {
  .o-block__right--2-3 {
    flex: 0 0 auto;
    width: calc(41.6666666667% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-left: calc(8.3333333333% + 1.25rem / 2);
  }
}
.o-block__cta {
  display: inline-block;
  position: relative;
}
.o-block__cta .a-btn {
  margin-top: 0;
}
.o-block__bird {
  position: absolute;
  top: 5px;
  right: 10px;
  transform: translateY(-100%);
  width: 37px;
  fill: #e9660a;
}
.o-block__bird--tertiary {
  fill: #e9b344;
}
.o-block__link {
  display: block;
  margin-top: 20px;
}
.o-block__leaves {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
  opacity: 0;
  transition: opacity 0.15s;
}
.o-block__private {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 0%, black 82%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}
.o-block__private .a-text {
  max-width: 1080px;
}
.o-block__president {
  max-width: 200px;
  margin: auto;
  clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
}
.o-block__president img {
  display: block;
}

.o-footer {
  background-color: #4b0e20;
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: center;
}
@media print, screen and (min-width: 64em) {
  .o-footer {
    text-align: left;
    padding-top: 80px;
    padding-bottom: 40px;
  }
}
.o-footer__container {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}
@media print, screen and (min-width: 64em) {
  .o-footer__container {
    margin-bottom: 0;
  }
}
.o-footer__column {
  flex: 0 0 auto;
  width: calc(100% - 1.25rem);
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  margin-bottom: 40px;
}
@media print, screen and (min-width: 64em) {
  .o-footer__column {
    flex: 0 0 auto;
    width: calc(16.6666666667% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 85.375em) {
  .o-footer__column {
    flex: 0 0 auto;
    width: calc(25% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
}
@media print, screen and (min-width: 64em) {
  .o-footer__column:nth-child(2) {
    flex: 0 0 auto;
    width: calc(66.6666666667% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
}
@media screen and (min-width: 85.375em) {
  .o-footer__column:nth-child(2) {
    flex: 0 0 auto;
    width: calc(58.3333333333% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
}
@media screen and (min-width: 85.375em) {
  .o-footer__column:nth-child(3) {
    flex: 0 0 auto;
    width: calc(16.6666666667% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
}
.o-footer__logo {
  display: inline-block;
  margin-bottom: 20px;
}
@media print, screen and (min-width: 64em) {
  .o-footer__logo {
    margin-bottom: 40px;
  }
}
.o-footer__logo img {
  display: inline-block;
  width: 140px;
}
@media print, screen and (min-width: 64em) {
  .o-footer__logo img {
    width: 200px;
  }
}
@media print, screen and (min-width: 64em) {
  .o-footer .m-nav-main {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: -40px;
  }
}
.o-footer .m-nav-main__item {
  margin-bottom: 0;
}
@media print, screen and (min-width: 64em) {
  .o-footer .m-nav-main__item {
    width: 33.333%;
    margin-right: 0;
    margin-bottom: 40px;
    padding: 0 20px;
    box-sizing: border-box;
  }
}
@media print, screen and (min-width: 64em) {
  .o-footer .m-nav-main__item:hover .m-nav-main__sub {
    transform: none;
  }
}
.o-footer .m-nav-main__link {
  display: inline-block;
  padding: 10px 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #e9b344;
  font-size: 16px;
  font-size: 1rem;
  border-bottom: none;
  text-decoration: none;
}
@media print, screen and (min-width: 64em) {
  .o-footer .m-nav-main__link {
    padding: 0;
  }
}
.o-footer .m-nav-main__link:after {
  border-top: 6px solid #e9b344;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
@media print, screen and (min-width: 64em) {
  .o-footer .m-nav-main__link:after {
    display: none;
  }
}
@media print, screen and (min-width: 64em) {
  .o-footer .m-nav-main__sub {
    transform: none;
    pointer-events: none;
    position: static;
    background: transparent;
    margin-top: 20px;
    min-width: auto;
    opacity: 1;
  }
}
.o-footer .m-nav-main__sub:before {
  height: 5px;
}
.o-footer .m-nav-main__sub:after {
  content: "";
  display: block;
  height: 5px;
}
@media print, screen and (min-width: 64em) {
  .o-footer .m-nav-main__sub:after {
    display: none;
  }
}
@media print, screen and (min-width: 64em) {
  .o-footer .m-nav-main__sub-item {
    opacity: 1;
    transform: none;
    margin-bottom: 10px;
  }
}
@media print, screen and (min-width: 64em) {
  .o-footer .m-nav-main__sub-item:last-child {
    margin-bottom: 0;
  }
}
.o-footer .m-nav-main__sub-link {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  text-decoration: none;
}
@media print, screen and (min-width: 64em) {
  .o-footer .m-nav-main__sub-link {
    padding: 0;
    color: #fff;
  }
}
@media screen and (min-width: 80em) {
  .o-footer .m-nav-main__sub-link:hover {
    background: transparent;
    text-decoration: underline;
  }
}
.o-footer__bot {
  flex: 0 0 auto;
  width: calc(100% - 1.25rem);
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}
@media print, screen and (min-width: 64em) {
  .o-footer__bot {
    margin-top: 80px;
  }
}

.o-top {
  margin-top: 20px;
  margin-bottom: 20px;
}
@media print, screen and (min-width: 64em) {
  .o-top {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}
@media print, screen and (min-width: 64em) {
  .o-top--visual {
    margin-top: 20px;
  }
}
.o-top--visual .o-top__container {
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}
.o-top--visual .o-top__container:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.25) 82%);
}
@media screen and (min-width: 85.375em) {
  .o-top--visual .o-top__container:before {
    right: 10px;
    left: 10px;
  }
}
.o-top--visual .o-top__container img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (min-width: 85.375em) {
  .o-top--visual .o-top__container img {
    max-width: 1300px;
  }
}
.o-top--visual .o-top__content {
  padding-top: 80px;
  padding-bottom: 60px;
}
@media print, screen and (min-width: 64em) {
  .o-top--visual .o-top__content {
    padding-top: 120px;
    padding-bottom: 80px;
  }
}
@media screen and (min-width: 105em) {
  .o-top--visual .o-top__content {
    padding-top: 160px;
    padding-bottom: 120px;
  }
}
.o-top--visual .o-top__content > * {
  position: relative;
  z-index: 1;
}
.o-top--visual .o-top__content .a-heading {
  text-shadow: 0 0 12px rgba(0, 0, 0, 0.5);
}
.o-top__container  {
  display: flex;
  flex-flow: row wrap;
}
.o-top__content {
  flex: 0 0 auto;
  width: calc(100% - 1.25rem);
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}
@media print, screen and (min-width: 64em) {
  .o-top__content {
    flex: 0 0 auto;
    width: calc(75% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
}
@media screen and (min-width: 80em) {
  .o-top__content {
    flex: 0 0 auto;
    width: calc(66.6666666667% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-left: calc(8.3333333333% + 1.25rem / 2);
  }
}
.o-top__content--centered {
  text-align: center;
}
.o-top__content .a-text {
  max-width: 680px;
}
.o-top .a-heading, .o-top .a-text {
  opacity: 0;
}
.o-top .a-heading.splitting, .o-top .a-text.splitting {
  opacity: 1;
}
.o-top .a-heading .word, .o-top .a-text .word {
  display: inline-block;
}
.o-top .a-btn {
  opacity: 0;
}
.o-top__categories {
  margin-bottom: -5px;
  opacity: 0;
}
.o-top__categories span {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  border: 1px solid #fff;
  padding: 5px 10px;
  color: #fff;
}

.o-body--home .o-top {
  margin-top: 0;
}